import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import * as OrderUtil from '../../Utils/OrderUtil';
import {getItemSummary} from '../../Utils/CartUtil';
import * as AppActions from '../../AppActions';
import {DELIVERABLE_ACTIONS_DISPLAY, INVOICE_TYPE} from '../../dictionary';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as PermissionUtil from '../../Utils/PermissionUtil';
import {useOutlet} from 'reconnect.js';
import * as CouponUtil from '../../Utils/CouponUtil';

export default function LayaOrderExtra(props) {
  const {context, path, profile} = props;
  const {instance} = context;
  const helper = new OrderUtil.OrderDisplayHelper(instance);
  const [point, setPoint] = React.useState();
  const [stamps, setStamps] = React.useState([]);
  const [user] = useOutlet('user');

  React.useEffect(() => {
    async function getPoint(order_id = '---') {
      try {
        return await JStorage.fetchOneDocument('user_point', {order_id});
      } catch (e) {
        console.log(e);
      }
    }
    async function getStamps(order = '---') {
      try {
        return await JStorage.fetchAllDocuments('stamp', {order});
      } catch (e) {
        console.log(e);
        return [];
      }
    }
    if (instance) {
      (async () => {
        let [point, stamps] = await Promise.all([
          getPoint(instance.order_id),
          getStamps(instance.id),
        ]);
        setPoint(point);
        setStamps(stamps);
      })();
    }
  }, [instance]);

  async function cancelOrder() {
    const isConfirmed = window.confirm('此動作無法復原, 確定要繼續嗎?');
    if (isConfirmed) {
      AppActions.setLoading(true);
      try {
        await AppActions.delay(500);
        await AppActions.adminCancelOrder(instance.id);
        setTimeout(() => {
          window.alert('訂單已取消');
          AppActions.navigate('/admin/store_orders/');
        }, 200);
      } catch (ex) {
        console.warn('LayaOrderExtra.cancelOrder ex', ex);
      }
      AppActions.setLoading(false);
    }
  }

  async function refundOrder() {
    const isConfirmed = window.confirm('此動作無法復原, 確定要繼續嗎?');
    if (isConfirmed) {
      AppActions.setLoading(true);
      try {
        await AppActions.delay(500);
        await AppActions.adminRefundOrder({
          order_number: instance.order_number,
        });
        AppActions.addHistory({
          collection: 'order',
          previous: {id: instance.id, payment_status: instance.payment_status},
          next: {id: instance.id, payment_status: 'refunded'},
        });
        setTimeout(() => {
          window.alert('已送出退款請求');
          AppActions.navigate('/admin/store_orders/');
        }, 200);
      } catch (ex) {
        console.warn('LayaOrderExtra.cancelOrder ex', ex);
      }
      AppActions.setLoading(false);
    }
  }

  async function syncWeibyOrder() {
    AppActions.setLoading(true);
    try {
      await AppActions.syncWeibyOrder(instance.id);
      Ant.message.success('同步完成');
    } catch (error) {
      console.log(error);
      Ant.message.error('同步失敗');
    }
    AppActions.setLoading(false);
  }

  const posReq = instance.pos_info?.request || {};
  const posRes = {...(instance.pos_info || {})};
  delete posRes.request;
  const couponDiscountItem = instance.discount_items.find(
    (x) => !!x.promotion?.coupon,
  );

  const discountItems = instance?.discount_items || [];

  return (
    <Wrapper>
      {!instance?.config?.table && (
        <section>
          <h2>取餐人資訊</h2>
          <div className="field">
            <label>姓名</label>
            <div>{instance?.config?.buyer_name}</div>
          </div>
          <div className="field">
            <label>電話</label>
            <div>{instance?.config?.buyer_phone}</div>
          </div>
        </section>
      )}

      {profile && (
        <section>
          <h2>會員資訊</h2>
          <div className="field">
            <label>姓名</label>
            <a
              target="_blank"
              href={'/admin/users/?action=detail&id=' + profile.id}
              rel="noreferrer">
              {profile.name}
            </a>
          </div>
          <div className="field">
            <label>電話</label>
            <div>{profile.phone}</div>
          </div>
        </section>
      )}

      <section>
        <h2>點餐資訊</h2>
        <div className="field">
          <label>送單時間（建立）</label>
          <div>{helper.createdDateTime()}</div>
        </div>

        <div className="field">
          <label>類型</label>
          <div>{helper.orderType()}</div>
        </div>

        <div className="field">
          <label>桌邊點餐</label>
          <div>{helper.isTableOrder() ? '是' : '否'}</div>
          <div> {instance.config?.table}</div>
        </div>

        <div className="field">
          <label>外送</label>
          <div>{instance.is_uber_direct ? '是' : '否'}</div>
          {instance.is_uber_direct && (
            <div>
              <div>
                {
                  DELIVERABLE_ACTIONS_DISPLAY[
                    instance.uber_direct_config.deliverable_action
                  ]
                }
              </div>
              {instance.uber_direct_responses?.[0]?.kind === 'error' && (
                <div>
                  發生錯誤：{instance.uber_direct_responses?.[0]?.message}
                </div>
              )}
              <div>{instance.uber_direct_config.dropoff_notes}</div>
              <div>{instance.uber_direct_responses?.[0]?.dropoff?.address}</div>
              {Array.isArray(instance.uber_direct_responses) &&
                instance.uber_direct_responses.map((r, idx) => (
                  <a href={r?.tracking_url} key={idx}>
                    {r?.tracking_url}
                  </a>
                ))}
            </div>
          )}
        </div>

        <div className="field">
          <label>來源</label>
          <div>{instance?.origin}</div>
        </div>

        <div className="field">
          <label>付款方式</label>
          <div>
            {helper.paymentType()} {instance.payment_type}{' '}
            {instance.payment_subtype}
          </div>
        </div>

        {(instance?.origin === 'foodpanda' ||
          instance?.origin === 'ubereats') && (
          <React.Fragment>
            <div className="field">
              <label>取餐單號</label>
              <div>
                {instance.pickup_no}{' '}
                {instance.origin_order?.code
                  ? '(' + instance.origin_order?.code + ')'
                  : null}
              </div>
            </div>
          </React.Fragment>
        )}

        {helper.invoiceType().enable && (
          <div className="field">
            <label>發票資訊</label>
            <div>
              {INVOICE_TYPE[helper.invoiceType().invoiceType]}{' '}
              <span style={{marginLeft: 20}}>
                {helper.invoiceType().carrier}
              </span>
            </div>
          </div>
        )}

        <div className="field">
          <label>訂單備註</label>
          <div>{instance.config?.note || '---'}</div>
        </div>

        <div className="field">
          <label>餐點內容</label>
          {instance.items.map((cartItem, idx) => {
            const hideDetailPrice = !!instance.store.price_delta;
            const discounts = instance.discount_items.filter(
              (dis) => dis.detail?.item_index === idx,
            );
            const pos_discounts = (instance?.pos_discount_items || []).filter(
              (x) => x.item_index === idx,
            );

            return (
              <div className="cart-item" key={idx}>
                <div className="title-bar">
                  <div style={{flex: 1}}>
                    {cartItem.product.name} x {cartItem.qty}
                  </div>
                </div>

                <div className="item-summary">
                  <div>
                    {getItemSummary(cartItem, {hidePrice: hideDetailPrice})}
                  </div>

                  {cartItem.drink && (
                    <div>
                      搭配飲料 / {cartItem.drink.product.name}
                      {!hideDetailPrice &&
                        ' + ' + cartItem.drink.product.price}{' '}
                      {getItemSummary(cartItem.drink, {
                        hidePrice: hideDetailPrice,
                      })}
                    </div>
                  )}

                  {cartItem.add_on && (
                    <div>
                      加購商品 / {cartItem.add_on.product.name}
                      {!hideDetailPrice && ' + ' + cartItem.add_on.total}{' '}
                      {getItemSummary(cartItem.add_on, {
                        hidePrice: hideDetailPrice,
                      })}
                    </div>
                  )}

                  {cartItem.note && <div>備註 / {cartItem.note}</div>}

                  {discounts.map((dis, disIdx) => (
                    <div key={disIdx} style={{display: 'flex'}}>
                      <div style={{marginRight: 10}}>
                        {dis.promotion.name || '---'}
                      </div>
                      {!!dis.promotion.coupon && (
                        <div style={{marginRight: 6}}>
                          優惠券 #{CouponUtil.getId(dis.promotion.coupon)}
                        </div>
                      )}

                      {!hideDetailPrice && <div>$-{dis.discount_value}</div>}
                    </div>
                  ))}
                  {pos_discounts.map((dis, disIdx) => (
                    <div key={disIdx} style={{display: 'flex'}}>
                      <div style={{marginRight: 10}}>POS折扣</div>
                      {!hideDetailPrice && (
                        <div>
                          {dis.name}($-{dis.discount_value})
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                <div className="item-price">
                  <div
                    style={{
                      flex: 1,
                      fontSize: 20,
                    }}>
                    $
                    {cartItem.total -
                      discounts.reduce((sum, x) => {
                        sum += x.discount_value;
                        return sum;
                      }, 0) -
                      pos_discounts.reduce((sum, x) => {
                        sum += x.discount_value;
                        return sum;
                      }, 0)}
                  </div>
                </div>
              </div>
            );
          })}

          <div style={{display: 'flex', fontSize: 20}}>
            <div style={{marginRight: 10}}>商品總額</div>
            <div>
              $
              {instance.subtotal -
                (instance.pos_discount_items || [])
                  .filter((x) => x.type === 'ITEM_DISCOUNT')
                  .reduce((sum, x) => {
                    sum += x.discount_value;
                    return sum;
                  }, 0)}
            </div>
          </div>

          {discountItems.length > 0 ? (
            <div style={{display: 'flex', fontSize: 20}}>
              <div style={{marginRight: 5}}>促銷活動</div>
              <div>
                -
                {discountItems.reduce((sum, x) => {
                  sum += x.discount_value;
                  return sum;
                }, 0)}
              </div>
            </div>
          ) : null}

          {couponDiscountItem && (
            <div style={{display: 'flex', fontSize: 20}}>
              <div style={{marginRight: 5}}>(包含優惠券</div>
              <div>
                {couponDiscountItem.promotion.name} #
                {CouponUtil.getId(couponDiscountItem.promotion.coupon)} -
                {couponDiscountItem.discount_value})
              </div>
            </div>
          )}

          {instance.uber_direct_fee ? (
            <div style={{display: 'flex', fontSize: 20}}>
              <div style={{marginRight: 10}}>外送費</div>
              <div>${instance.uber_direct_fee}</div>
            </div>
          ) : null}

          {(instance.pos_discount_items || []).filter(
            (x) => x.type === 'ORDER_DISCOUNT',
          ).length > 0 && (
            <div style={{display: 'flex', fontSize: 20}}>
              <div style={{marginRight: 10}}>POS折扣</div>
              <div>
                {(instance.pos_discount_items || [])
                  .filter((x) => x.type === 'ORDER_DISCOUNT')
                  .map((x) => `${x.name} (-$${x.discount_value})`)
                  .join(',')}
              </div>
            </div>
          )}

          <div style={{display: 'flex', fontSize: 20}}>
            <div style={{marginRight: 10}}>總額</div>
            <div>${instance.total}</div>
          </div>
        </div>
      </section>

      <section>
        <h2>分店資訊</h2>
        <div className="field">
          <label>名稱</label>
          <div>{instance.store?.name || '---'}</div>
        </div>

        <div className="field">
          <label>電話</label>
          <div>{instance.store?.phone || '---'}</div>
        </div>

        <div className="field">
          <label>地址</label>
          <div>{instance.store?.address || '---'}</div>
        </div>
      </section>

      <section>
        <h2>點數資訊(舊版)</h2>
        <div className="field">
          {!instance.owner ? (
            <div>匿名訂單</div>
          ) : point ? (
            <React.Fragment>
              <label>已獲得點數</label>
              <div>{point.point} 點</div>
              <label>發放時間</label>
              <div>{new Date(point.created).toLocaleString('sv')}</div>
            </React.Fragment>
          ) : (
            <div>無紀錄（尚未付款、舊訂單可能無紀錄、點數隔天發放）</div>
          )}
        </div>
      </section>

      <section>
        <h2>印花資訊(舊版)</h2>
        <div className="field">
          {!instance.owner ? (
            <div>匿名訂單</div>
          ) : stamps?.length > 0 ? (
            <React.Fragment>
              <label>已獲得印花</label>
              <div>{stamps.length} 點</div>
              <label>發放時間</label>
              <div>{new Date(stamps[0].created).toLocaleString('sv')} </div>
            </React.Fragment>
          ) : (
            <div>
              無紀錄（5分鐘後未付款、店家無開啟、未到金額門檻、活動已過）
            </div>
          )}
        </div>
      </section>

      <section>
        <h2>取得優惠券資訊(舊版)</h2>
        <div className="field">
          {!instance.owner ? (
            <div>匿名訂單</div>
          ) : Array.isArray(instance.scratch_card_event?.results) ? (
            <Ant.Table
              columns={[
                {title: '優惠券編號', key: 'id', dataIndex: 'id'},
                {title: '優惠券名稱', key: 'name', dataIndex: 'name'},
                {title: '取得時間', key: 'created', dataIndex: 'created'},
              ]}
              dataSource={instance.scratch_card_event?.results.map((x) => ({
                id: x ? CouponUtil.getId(x.award) : '',
                name: x ? x.award.name : '再接再厲',
                created: x
                  ? new Date(instance.created).toLocaleDateString('sv')
                  : '',
              }))}
              rowKey={(x) => x?.id || new Date().getTime()}
              pagination={false}
            />
          ) : Array.isArray(instance.draw_lots_event?.results) ? (
            <Ant.Table
              columns={[
                {title: '優惠券編號', key: 'id', dataIndex: 'id'},
                {title: '優惠券名稱', key: 'name', dataIndex: 'name'},
                {title: '取得時間', key: 'created', dataIndex: 'created'},
              ]}
              dataSource={instance.draw_lots_event?.results.map((x) => ({
                id: x ? CouponUtil.getId(x.award) : '',
                name: x ? x.award.name : '再接再厲',
                created: x
                  ? new Date(instance.created).toLocaleDateString('sv')
                  : '',
              }))}
              rowKey={(x) => x?.id || new Date().getTime()}
              pagination={false}
            />
          ) : Array.isArray(instance.coupon_distribution_event?.results) ? (
            <Ant.Table
              columns={[
                {title: '優惠券編號', key: 'id', dataIndex: 'id'},
                {title: '優惠券名稱', key: 'name', dataIndex: 'name'},
                {title: '取得時間', key: 'created', dataIndex: 'created'},
              ]}
              dataSource={instance.coupon_distribution_event?.results.map(
                (x) => ({
                  id: x ? CouponUtil.getId(x.award) : '',
                  name: x ? x.award.name : '再接再厲',
                  created: x
                    ? new Date(instance.created).toLocaleDateString('sv')
                    : '',
                }),
              )}
              rowKey={(x) => x?.id || new Date().getTime()}
              pagination={false}
            />
          ) : (
            <div>無紀錄</div>
          )}
        </div>
      </section>

      <section>
        <h2>活動回饋</h2>
        <div className="field">
          {!instance.owner ? (
            <div>匿名訂單</div>
          ) : (
            <Ant.Table
              columns={[
                {title: '獎項種類', key: 'type', dataIndex: 'type'},
                {title: '獎項名稱', key: 'name', dataIndex: 'name'},
                {title: '活動', key: 'event_name', dataIndex: 'event_name'},
                {title: '取得時間', key: 'created', dataIndex: 'created'},
              ]}
              dataSource={(instance.events || []).reduce((sum, event) => {
                return sum.concat(
                  (event.results || []).map((x) => ({
                    type: {COUPON: '優惠券', STAMP: '印花集章', POINT: '點數'}[
                      x?.award_type
                    ],
                    name: !x
                      ? '再接再厲'
                      : x.award_type === 'POINT'
                      ? x.award.point + '點(非即時)'
                      : x.award_type === 'STAMP'
                      ? x.award.stamp_count + '枚'
                      : x.award_type === 'COUPON'
                      ? x.award.name + ' #' + CouponUtil.getId(x.award)
                      : null,
                    event_name: event.name + (x?.is_costed ? '(點數)' : ''),
                    created: x
                      ? new Date(instance.created).toLocaleDateString('sv')
                      : '',
                  })),
                );
              }, [])}
              pagination={false}
            />
          )}
        </div>
      </section>

      <div className="bottom-action-bar">
        <Ant.Button
          type="primary"
          target="_blank"
          href={`/admin/history/?query=${encodeURIComponent(
            JSON.stringify({collection: 'order', document_id: instance.id}),
          )}`}>
          歷程紀錄
        </Ant.Button>
        <Ant.Button
          onClick={cancelOrder}
          type="primary"
          disabled={
            ['success', 'pending'].indexOf(instance?.payment_status) === -1 ||
            !PermissionUtil.writable({path, user})
          }>
          取消訂單
        </Ant.Button>

        <Ant.Button
          onClick={refundOrder}
          type="primary"
          disabled={
            ['tappay', 'jkopay', 'linepay'].indexOf(instance?.payment_type) ===
              -1 ||
            ['success'].indexOf(instance?.payment_status) === -1 ||
            !PermissionUtil.writable({path, user})
          }>
          退費
        </Ant.Button>

        <Ant.Button
          onClick={syncWeibyOrder}
          type="primary"
          disabled={!PermissionUtil.writable({path, user})}>
          同步訂單
        </Ant.Button>
      </div>

      <section>
        <h2>POS Info</h2>
        <div className="field">
          <label>Request</label>
          <textarea
            value={JSON.stringify(posReq, null, 2)}
            style={{height: 600, width: '100%'}}
            disabled
          />
        </div>

        <div className="field">
          <label>Response</label>
          <textarea
            value={JSON.stringify(posRes, null, 2)}
            style={{height: 600, width: '100%'}}
            disabled
          />
        </div>
      </section>

      <section>
        <h2>取得發票資訊</h2>
        {instance.invoice_detail ? (
          <React.Fragment>
            <div className="field">
              <label>已開立發票</label>
              <textarea
                value={JSON.stringify(instance.invoice_detail, null, 2)}
                style={{height: 400, width: '100%'}}
                disabled
              />
            </div>
          </React.Fragment>
        ) : (
          <div>尚未開立發票</div>
        )}
      </section>

      <section>
        <h2>退款資訊</h2>
        {instance.refund_info || instance.refund_detail ? (
          <React.Fragment>
            {instance.refund_info?.time && (
              <div className="field">
                <label>退款時間</label>
                <div>
                  {new Date(instance.refund_info?.time).toLocaleString('sv')}
                </div>
              </div>
            )}
            <div className="field">
              <label>退款詳細資訊</label>
              <textarea
                value={JSON.stringify(
                  instance.refund_info?.detail || instance.refund_detail,
                  null,
                  2,
                )}
                style={{height: 400, width: '100%'}}
                disabled
              />
            </div>
          </React.Fragment>
        ) : (
          <div>非退款訂單</div>
        )}
      </section>

      {instance.closing_info?.is_closing ? (
        <section>
          <h2>關帳資訊</h2>
          <div className="field">
            <label>關帳編號</label>
            <div>{instance.closing_info?.closing_id}</div>
          </div>

          <div className="field">
            <label>關帳時間</label>
            <div>
              {new Date(instance.closing_info?.closing_time).toLocaleString(
                'sv',
              )}
            </div>
          </div>

          {instance.payment_status === 'refunded' && (
            <React.Fragment>
              <div className="field">
                <label>退款關帳編號</label>
                <div>{instance.closing_info?.refund_closing_id}</div>
              </div>

              <div className="field">
                <label>退款關帳時間</label>
                <div>
                  {new Date(
                    instance.closing_info?.refund_closing_time,
                  ).toLocaleString('sv')}
                </div>
              </div>
            </React.Fragment>
          )}
        </section>
      ) : null}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: 800px;

  & > section {
    margin: 10px 0;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;

    & > h2 {
      border-left: 5px solid #ccc;
      padding-left: 8px;
      margin-bottom: 8px;
    }

    & > .field {
      & > label {
        display: block;
        color: #888;
      }
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  & > .bottom-action-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & > * {
      &:not(last-child) {
        margin-right: 10px;
      }
    }
  }
`;
