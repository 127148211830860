import {useEffect, useRef} from 'react';
import Config from '../../data.json';
import qs from 'query-string';

const currency = 'TWD';

const useGoogleAnalytics = (props) => {
  const {gaId, gtmId = null, location} = props;
  const prevPathname = useRef();

  useEffect(() => {
    if (gaId) {
      const gaScript = document.createElement('script');
      gaScript.async = true;
      gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
      document.head.appendChild(gaScript);

      window.dataLayer = window.dataLayer || [];
      window.gtag = function () {
        window.dataLayer.push(arguments);
      };
      window.gtag('js', new Date());
      window.gtag('config', gaId);
    }

    if (gtmId) {
      const gtmScriptTag = document.createElement('script');
      gtmScriptTag.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer', '${gtmId}');`;

      const gtmNoScriptTag = document.createElement('noscript');
      gtmNoScriptTag.innerHTML = `
                <iframe
            src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
            height="0"
            width="0"
            style="display:none;visibility:hidden"
          />
`;

      document.head.appendChild(gtmScriptTag);
      document.body.appendChild(gtmNoScriptTag);
    }
  }, [gaId, gtmId]);

  useEffect(() => {
    if (
      location.pathname.indexOf('/admin') === -1 &&
      location.pathname.indexOf('/mobile') === -1 &&
      prevPathname.current !== location.pathname
    ) {
      prevPathname.current = location.pathname;
      const params = qs.parse(location.search);

      let _params = {};
      if (
        params?.utm_source ||
        params?.utm_medium ||
        params?.utm_campaign ||
        params?.utm_term ||
        params?.utm_content
      ) {
        _params = {
          utm_source: params.utm_source,
          utm_medium: params.utm_medium,
          utm_campaign: params.utm_campaign,
          utm_term: params.utm_term,
          utm_content: params.utm_content,
        };
      }

      logScreenView({screen: location.pathname, ..._params});
    }
  }, [location?.pathname]);
};

function logEvent(event, data = {}) {
  data.stage = Config.stage;
  data.origin = 'web';
  // data.debug_mode = true; // if watch on DebugView then add debug_mode

  if (typeof window?.gtag !== 'undefined') {
    // window.gtag('event', 'test', {debug_mode: true}); if watch on DebugView then add debug_mode
    window.gtag('event', event, data);
  }

  /* 
  1. disabled adblock
  2. try to use pokemon production to debug
  */
}

function parseGAItem(product, qty) {
  const [item_category, item_category2] = (product?.labels[0] || '').split('-');
  return {
    item_id: product?.id,
    item_name: product?.name,
    item_category,
    item_category2,
    quantity: qty,
    price: product?.price,
  };
}

export function logCustomEvent() {
  logEvent(...arguments);
}

export async function logAddToCart({items, value}) {
  logEvent('add_to_cart', {
    items: items.map((item) => parseGAItem(item.product, item.qty)),
    currency,
    value,
  });
}

export async function logBeginCheckout({value, coupon, items}) {
  logEvent('begin_checkout', {
    currency,
    value,
    coupon,
    items: items.map((item) => parseGAItem(item.product, item.qty)),
  });
}

export async function logAddPaymentInfo({items, value, coupon, payment_type}) {
  logEvent('add_payment_info', {
    items: items.map((item) => parseGAItem(item.product, item.qty)),
    currency,
    value,
    coupon,
    payment_type,
  });
}

export async function logPurchase({coupon, items, value, transaction_id}) {
  logEvent('purchase', {
    coupon,
    currency,
    items: items.map((item) => parseGAItem(item.product, item.qty)),
    value,
    transaction_id,
  });
}

export async function logViewPromotion({
  items,
  creative_name,
  creative_slot,
  promotion_name,
}) {
  logEvent('view_promotion', {
    items: items.map((item) => parseGAItem(item.product, item.qty)),
    creative_name,
    creative_slot,
    promotion_name,
    location_id: 'taiwan',
  });
}

export async function logSelectPromotion({
  creative_name,
  creative_slot,
  items,
  promotion_name,
}) {
  logEvent('select_promotion', {
    creative_name,
    creative_slot,
    items: items.map((item) => parseGAItem(item.product, item.qty)),
    promotion_name,
    location_id: 'taiwan',
    promotion_id: promotion_name,
  });
}

function logScreenView({screen, ...rest}) {
  logEvent('screen_view', {
    screen_class: screen,
    screen_name: screen,
    ...rest,
  });
}

export default useGoogleAnalytics;
