import React from 'react';
import styled from 'styled-components';
import Config from '../../../data.json';
import {ChevronLeft} from '@styled-icons/feather/ChevronLeft';
import * as Ant from 'antd';
import qs from 'query-string';
import * as Dictionary from '../../dictionary';
import AdminSelectProductNameWidget from '../../Generators/AdminResource/AdminSelectProductNameWidget';
import AdminSelectVariantGroupWidget from '../../Generators/AdminResource/AdminSelectVariantGroupWidget';
import AdminDateTimeWidget from '../../Generators/AdminResource/AdminDateTimeWidget';
import RevSingleImageUploader from 'rev.sdk.js/Generic/CustomWidgets/RevSingleImageUploader';
import spec from '../../../content/admin/coupon_template.json';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {useOutlet} from 'reconnect.js';

const defaultValue = {
  event_name: '',
  name: '',
  subtitle: '',
  type: '',
  image: '',
  description:
    '1. 本券僅限LAYA NOW APP使用，每筆訂單僅限使用一張優惠券且不找零，一經使用恕不退還。\n2. 使用折價券折抵後，結帳金額不得為零。\n3. 本券僅適用於餐點費用折抵，恕不適用於早點到服務之運費。\n4. 使用本券的訂單如取消或退款，本券將失效不會歸還。\n5. 優惠券有使用期限，逾期後則無法使用，視同作廢且無法恢復。\n6. 優惠券不得兌換現金/找零或折換其他贈品。\n7. 拉亞漢堡保留隨時調整各項優惠券贈送/抵用辦法與終止之權利。',
  note: '',
  circulation: 0, //已被兌換次數
  threshold: 0,
  discount_value: 0,
  two_for_one_discount_product: '',
  exchange_discount_value: 0,
  exchange_discount_product: '',
  level_up_discount_value: 0,
  level_up_variants_group: '',
  redeem_rules: {
    period_type: 'days', // days | date
    period_value: 0,
    valid_stores: ['*'],
    force_uber_direct: false,
    force_reservation: false,
  },
  exchange_rules: {
    allow_points: false,
    cost_points: 10,
    start_time: null,
    end_time: null,
    is_circulation_restricted: false,
    restrict_circulation: 0,
  },
};

export default function AdminCouponTemplates(props) {
  const {location} = props;
  const params = qs.parse(location.search);
  const {action, id} = params;
  const [record, _setRecord] = React.useState({...defaultValue});
  const [couponTemplates, setCouponTemplates] = useOutlet('coupon_templates');

  const setRecord = React.useCallback(
    (value) => _setRecord((prev) => ({...prev, ...value})),
    [],
  );

  const submit = async () => {
    AppActions.setLoading(true);
    let resp;

    try {
      if (!record.name) {
        throw new Error('標題不可為空');
      }
      if (!record.type) {
        throw new Error('請選擇類型');
      }

      if (
        record.redeem_rules.period_type === 'date' &&
        record.redeem_rules.period_value < record.exchange_rules.end_time
      ) {
        throw new Error('折抵時間不可小於結束兌換時間');
      }

      if (action === 'create') {
        resp = await JStorage.createDocument(spec.collection, record);
        Ant.message.success('優惠券樣板創建成功');
        AppActions.navigate(
          `/admin/coupon_template?action=detail&id=${resp.id}`,
        );
      } else {
        resp = await JStorage.updateDocument(
          spec.collection,
          {id: record.id},
          record,
        );
        Ant.message.success('優惠券樣板更新成功');
      }

      setCouponTemplates(await JStorage.fetchAllDocuments('coupon_template'));
    } catch (e) {
      console.warn('error', e);
      Ant.message.error('發生錯誤 ' + e.message);
    }
    AppActions.setLoading(false);
  };

  React.useEffect(() => {
    (async () => {
      if (id) {
        AppActions.setLoading(true);
        try {
          _setRecord(await JStorage.fetchOneDocument(spec.collection, {id}));
        } catch (e) {
          console.warn(e);
        }
        AppActions.setLoading(false);
      }
    })();
  }, [id]);

  return (
    <Wrapper>
      <Row>
        <BackButton
          className="resource-back-button"
          onClick={() => AppActions.navigate(spec.backPath || spec.path)}>
          <ChevronLeft size={24} />
          <div className="back-btn-text">{spec.backTitle || '返回'}</div>
        </BackButton>
        {action === 'create' ? (
          <h1 style={{marginLeft: 10}}>{`創建${spec.name}`}</h1>
        ) : (
          <h1 style={{marginLeft: 10}}>{`${spec.name}詳情`}</h1>
        )}
        {action === 'detail' && (
          <Ant.Button
            type="primary"
            style={{marginLeft: 10}}
            href={`/admin/coupon_codes?template=${record.id}`}
            target="_blank">
            設定優惠券代碼
          </Ant.Button>
        )}
      </Row>

      <h2>優惠券基本設定</h2>

      <Ant.Row>
        <Ant.Col span={2} className="label">
          活動名稱 (等同前台領取方式)
        </Ant.Col>
        <Ant.Col span={6}>
          <Ant.Input
            value={record.event_name}
            onChange={(e) => setRecord({event_name: e.target.value})}
          />
        </Ant.Col>
        <Ant.Col span={2} className="label">
          標題
        </Ant.Col>
        <Ant.Col span={6}>
          <Ant.Input
            value={record.name}
            onChange={(e) => setRecord({name: e.target.value})}
          />
        </Ant.Col>
        <Ant.Col span={2} className="label">
          副標題
        </Ant.Col>
        <Ant.Col span={6}>
          <Ant.Input
            value={record.subtitle}
            onChange={(e) => setRecord({subtitle: e.target.value})}
          />
        </Ant.Col>
      </Ant.Row>

      <Ant.Row>
        <Ant.Col span={2} className="label">
          折抵時間
        </Ant.Col>
        <Ant.Col span={22}>
          <Ant.Radio.Group
            value={record.redeem_rules.period_type}
            onChange={(e) => {
              let value = e.target.value;
              _setRecord((prev) => ({
                ...prev,
                redeem_rules: {
                  ...prev.redeem_rules,
                  period_type: value,
                  period_value: 0,
                },
              }));
            }}>
            <Ant.Space direction="vertical">
              <Ant.Radio value="days">
                指定{' '}
                {record.redeem_rules.period_type === 'days' && (
                  <React.Fragment>
                    <Ant.Input
                      type="number"
                      value={record.redeem_rules.period_value}
                      onChange={(e) => {
                        let value = parseInt(e.target.value);
                        _setRecord((prev) => ({
                          ...prev,
                          redeem_rules: {
                            ...prev.redeem_rules,
                            period_value: value,
                          },
                        }));
                      }}
                      style={{width: 100}}
                    />{' '}
                    天數(從兌換時間起算)
                  </React.Fragment>
                )}
              </Ant.Radio>
              <Ant.Radio value="date">
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{marginRight: 5}}>指定折抵結束日期</div>
                  {record.redeem_rules.period_type === 'date' && (
                    <AdminDateTimeWidget
                      value={record.redeem_rules.period_value}
                      onChange={(value) =>
                        _setRecord((prev) => ({
                          ...prev,
                          redeem_rules: {
                            ...prev.redeem_rules,
                            period_value: value,
                          },
                        }))
                      }
                    />
                  )}
                </div>
              </Ant.Radio>
            </Ant.Space>
          </Ant.Radio.Group>
        </Ant.Col>
      </Ant.Row>

      <Ant.Row>
        <Ant.Col span={2} className="label">
          開始兌換時間
        </Ant.Col>
        <Ant.Col span={6}>
          <AdminDateTimeWidget
            value={record.exchange_rules.start_time}
            onChange={(value) =>
              _setRecord((prev) => ({
                ...prev,
                exchange_rules: {
                  ...prev.exchange_rules,
                  start_time: value,
                },
              }))
            }
          />
        </Ant.Col>

        <Ant.Col span={2} className="label">
          結束兌換時間
        </Ant.Col>
        <Ant.Col span={14}>
          <AdminDateTimeWidget
            value={record.exchange_rules.end_time}
            onChange={(value) =>
              _setRecord((prev) => ({
                ...prev,
                exchange_rules: {
                  ...prev.exchange_rules,
                  end_time: value,
                },
              }))
            }
          />
        </Ant.Col>
      </Ant.Row>

      <Ant.Row>
        <Ant.Col span={2} className="label">
          圖示
        </Ant.Col>
        <Ant.Col span={6}>
          <RevSingleImageUploader
            value={record.image}
            onChange={(value) => setRecord({image: value})}
            schema={{}}
          />
        </Ant.Col>
        <Ant.Col span={2} className="label">
          注意事項
        </Ant.Col>
        <Ant.Col span={14}>
          <Ant.Input.TextArea
            rows={2}
            value={record.description}
            onChange={(e) => setRecord({description: e.target.value})}
          />
        </Ant.Col>
      </Ant.Row>

      <Ant.Row>
        <Ant.Col span={2} className="label">
          備註
        </Ant.Col>
        <Ant.Col span={6}>
          <Ant.Input.TextArea
            rows={2}
            value={record.note}
            onChange={(e) => setRecord({note: e.target.value})}
          />
        </Ant.Col>
        <Ant.Col span={2} className="label">
          使用條件
        </Ant.Col>
        <Ant.Col span={6}>
          <div>
            <Ant.Checkbox
              checked={record.redeem_rules?.force_uber_direct}
              onChange={(e) => {
                const value = e.target.checked;
                _setRecord((prev) => ({
                  ...prev,
                  redeem_rules: {
                    ...prev.redeem_rules,
                    force_uber_direct: value,
                  },
                }));
              }}>
              僅使用於早點到
            </Ant.Checkbox>
            <br />
            <Ant.Checkbox
              checked={record.redeem_rules?.force_reservation}
              onChange={(e) => {
                const value = e.target.checked;
                _setRecord((prev) => ({
                  ...prev,
                  redeem_rules: {
                    ...prev.redeem_rules,
                    force_reservation: value,
                  },
                }));
              }}>
              僅使用於預約訂單
            </Ant.Checkbox>
          </div>
        </Ant.Col>
        <Ant.Col span={2} className="label">
          已發放數量
        </Ant.Col>
        <Ant.Col
          span={6}
          style={{
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}>
          <div>{record.circulation}</div>
          <div className="hint">
            可能超過發放數量限制，因為優惠券代碼兌換數量可能超過優惠券樣板限制
          </div>
        </Ant.Col>
      </Ant.Row>

      <h2>優惠券類型設定</h2>
      <Ant.Row>
        <Ant.Col span={2} className="label">
          類型
        </Ant.Col>
        <Ant.Col span={6}>
          <Ant.Select
            options={Object.keys(Dictionary.COUPON_TEMPLATE_TYPE).map(
              (type) => ({
                value: type,
                label: Dictionary.COUPON_TEMPLATE_TYPE[type],
              }),
            )}
            value={record.type}
            onChange={(value) => {
              console.log('select', value);
              setRecord({type: value});
            }}
            style={{width: '100%'}}
          />
        </Ant.Col>
        {record.type === 'COUPON_CASH_DISCOUNT' ? (
          <React.Fragment>
            <Ant.Col span={2} className="label">
              門檻金額
            </Ant.Col>
            <Ant.Col span={6}>
              <Ant.Input
                type="number"
                min={0}
                value={record.threshold}
                onChange={(e) => {
                  let value = parseInt(e.target.value);
                  setRecord({threshold: value});
                }}
              />
            </Ant.Col>
            <Ant.Col span={2} className="label">
              折價金額
            </Ant.Col>
            <Ant.Col span={6}>
              <Ant.Input
                type="number"
                min={0}
                value={record.discount_value}
                onChange={(e) => {
                  let value = parseInt(e.target.value);
                  setRecord({discount_value: value});
                }}
              />
            </Ant.Col>
          </React.Fragment>
        ) : record.type === 'COUPON_TWO_FOR_ONE' ? (
          <React.Fragment>
            <Ant.Col span={2} className="label">
              指定餐點
            </Ant.Col>
            <Ant.Col span={14}>
              <AdminSelectProductNameWidget
                value={record.two_for_one_discount_product}
                onChange={(name) =>
                  setRecord({two_for_one_discount_product: name})
                }
              />
            </Ant.Col>
          </React.Fragment>
        ) : record.type === 'COUPON_LEVEL_UP' ? (
          <React.Fragment>
            <Ant.Col span={2} className="label">
              折價金額
            </Ant.Col>
            <Ant.Col span={6}>
              <Ant.Input
                type="number"
                min={0}
                value={record.level_up_discount_value}
                onChange={(e) => {
                  let value = parseInt(e.target.value);
                  setRecord({level_up_discount_value: value});
                }}
              />
            </Ant.Col>
            <Ant.Col span={2} className="label">
              優惠選項群組
            </Ant.Col>
            <Ant.Col span={6}>
              <AdminSelectVariantGroupWidget
                value={record.level_up_variants_group}
                onChange={(value) =>
                  setRecord({level_up_variants_group: value})
                }
              />
            </Ant.Col>
          </React.Fragment>
        ) : record.type === 'COUPON_EXCHANGE' ? (
          <React.Fragment>
            <Ant.Col span={2} className="label">
              折價金額
            </Ant.Col>
            <Ant.Col span={6}>
              <Ant.Input
                type="number"
                min={0}
                value={record.exchange_discount_value}
                onChange={(e) => {
                  let value = parseInt(e.target.value);
                  setRecord({exchange_discount_value: value});
                }}
              />
            </Ant.Col>
            <Ant.Col span={2} className="label">
              指定餐點
            </Ant.Col>
            <Ant.Col span={6}>
              <AdminSelectProductNameWidget
                value={record.exchange_discount_product}
                onChange={(name) =>
                  setRecord({exchange_discount_product: name})
                }
              />
            </Ant.Col>
          </React.Fragment>
        ) : record.type === 'COUPON_DRINK_EXCHANGE' ? (
          <React.Fragment>
            <Ant.Col span={2} className="label">
              兌換飲料
            </Ant.Col>
            <Ant.Col span={14}>
              <AdminSelectProductNameWidget
                value={record.drink_exchange_discount_product}
                onChange={(value) =>
                  setRecord({drink_exchange_discount_product: value})
                }
              />
            </Ant.Col>
            <Ant.Col span={2} className="label">
              飲料折價
            </Ant.Col>
            <Ant.Col span={6}>
              <Ant.Input
                type="number"
                min={0}
                value={record.drink_exchange_discount_value}
                onChange={(e) => {
                  let value = parseInt(e.target.value);
                  setRecord({drink_exchange_discount_value: value});
                }}
              />
            </Ant.Col>
            <Ant.Col span={2} className="label">
              指定溫度
            </Ant.Col>
            <Ant.Col span={6}>
              <Ant.Select
                options={[
                  {label: '不限', value: null},
                  {label: '熱', value: '熱'},
                  {label: '溫', value: '溫'},
                  {label: '冰', value: '冰'},
                ]}
                value={record.drink_specified_temperature}
                onChange={(value) => {
                  setRecord({drink_specified_temperature: value});
                }}
                style={{width: '100%'}}
              />
            </Ant.Col>
            <Ant.Col span={2} className="label">
              指定大小
            </Ant.Col>
            <Ant.Col span={6}>
              <Ant.Select
                options={[
                  {label: '不限', value: null},
                  {label: '大', value: '大'},
                  {label: '中', value: '中'},
                  {label: '小', value: '小'},
                ]}
                value={record.drink_specified_size}
                onChange={(value) => {
                  setRecord({drink_specified_size: value});
                }}
                style={{width: '100%'}}
              />
            </Ant.Col>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Ant.Col span={16}></Ant.Col>
          </React.Fragment>
        )}
      </Ant.Row>
      <h2>點數兌換</h2>
      <Ant.Row>
        <Ant.Col span={2} className="label">
          是否可使用點數兌換
        </Ant.Col>
        <Ant.Col span={6}>
          <Ant.Radio.Group
            value={record.exchange_rules.allow_points}
            onChange={(e) => {
              let value = e.target.value;

              console.log('value', value);
              _setRecord((prev) => ({
                ...prev,
                exchange_rules: {...prev.exchange_rules, allow_points: value},
              }));
            }}>
            <Ant.Radio value={true}>是</Ant.Radio>
            <Ant.Radio value={false}>否</Ant.Radio>
          </Ant.Radio.Group>
        </Ant.Col>
        <Ant.Col span={2} className="label">
          點數面額
        </Ant.Col>
        <Ant.Col span={6}>
          <Ant.Input
            type="number"
            value={record.exchange_rules.cost_points}
            onChange={(e) => {
              let value = e.target.value;
              _setRecord((prev) => ({
                ...prev,
                exchange_rules: {...prev.exchange_rules, cost_points: value},
              }));
            }}
          />
        </Ant.Col>
        <Ant.Col span={2} className="label">
          發放數量
        </Ant.Col>
        <Ant.Col
          span={6}
          style={{flexDirection: 'column', alignItems: 'flex-start'}}>
          <Ant.Radio.Group
            style={{}}
            value={record.exchange_rules.is_circulation_restricted}
            onChange={(e) => {
              let value = e.target.value;
              _setRecord((prev) => ({
                ...prev,
                exchange_rules: {
                  ...prev.exchange_rules,
                  is_circulation_restricted: value,
                },
              }));
            }}>
            <Ant.Space direction="vertical">
              <Ant.Radio value={false}>不限制</Ant.Radio>
              <Ant.Radio value={true}>
                限制{' '}
                <Ant.Input
                  type="number"
                  min={0}
                  style={{width: 200}}
                  value={record.exchange_rules.restrict_circulation}
                  onChange={(e) => {
                    let value = parseInt(e.target.value);
                    _setRecord((prev) => ({
                      ...prev,
                      exchange_rules: {
                        ...prev.exchange_rules,
                        restrict_circulation: value,
                      },
                    }));
                  }}
                />
              </Ant.Radio>
            </Ant.Space>
          </Ant.Radio.Group>
        </Ant.Col>
      </Ant.Row>

      <Row style={{marginTop: 10}}>
        <div style={{flex: 1}} />
        <Ant.Button type="primary" onClick={submit}>
          確認
        </Ant.Button>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;

  & h1 {
    font-size: 32px;
  }

  & h2 {
    margin: 10px 0;
  }

  .ant-row {
    border-left: solid 1px #ccc;
    border-top: solid 1px #ccc;
  }
  .ant-row + .ant-row {
    border-top: 0;
  }

  .ant-col {
    padding: 6px;
    display: flex;
    align-items: center;
    border-right: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
  }

  .label {
    background-color: #ffe6a1;
  }

  .hint {
    color: #ccc;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  border-bottom: 1px solid transparent;
  padding: 5px 10px 5px 0px;
  background-color: transparent;
  cursor: pointer;

  & > .back-btn-text {
    font-size: 1.2rem;
  }

  &:hover {
    border-bottom: 1px solid black;
  }
`;
