import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import Theme from '../../Theme';
import HotProducts from '../HotProducts';
import {ChevronDown} from '@styled-icons/octicons/ChevronDown';
import Modal from '../Modal';

function CategoryMenuContent(props) {
  const {categories, onCatClick, products, onProductClick, setVisible} = props;
  const [landing] = useOutlet('landing');

  const mapCatItems = (item) => {
    for (const l2 of categories) {
      if (l2.name === item.cat) {
        return l2;
      }
    }
    return null;
  };

  return (
    <MenuContent>
      <div className="header">
        <div className="title" style={{flex: 1}}>
          選擇類別
        </div>
        <ChevronDown
          color="#545F71"
          size={40}
          style={{padding: 5}}
          onClick={() => setVisible(false)}
        />
      </div>
      <div className="block">
        <div className="title">人氣單品不管怎麼選都美味</div>
        <HotProducts onClick={onProductClick} />
      </div>

      <div className="block">
        <div className="title">招牌特色餐點開啟你特別的一天</div>

        <div className="grid">
          {(landing?.cat_menu_specials || [])
            .map(mapCatItems)
            .filter((catL2) => !!catL2)
            .map((catL2, idx_) => {
              return (
                <div
                  key={idx_}
                  className="grid-item"
                  onClick={() => onCatClick(catL2.name)}>
                  <div className="img-wrapper">
                    <img src={catL2.image} alt={'category'} />
                  </div>
                  <div>{catL2.display || catL2.name}</div>
                </div>
              );
            })}
          <div className="grid-item" />
          <div className="grid-item" />
        </div>
      </div>

      <div className="block">
        <div className="title">熟悉的餐點最享受</div>

        <div className="grid">
          {(landing?.cat_menu_singles || [])
            .map(mapCatItems)
            .filter((catL2) => !!catL2)
            .map((catL2, idx_) => {
              return (
                <div
                  key={idx_}
                  className="button"
                  onClick={() => onCatClick(catL2.name)}>
                  {/* <img src={catL2.image} alt={'category'} /> */}
                  <div>{catL2.display || catL2.name}</div>
                </div>
              );
            })}
          <div className="button" style={{border: 0}} />
          <div className="button" style={{border: 0}} />
        </div>
      </div>
    </MenuContent>
  );
}

const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 10;
  color: ${Theme.colors.text};
  .header {
    display: flex;
    align-items: center;
    padding: 0 20px;
    border-bottom: solid 1px #eee;
  }
  .block {
    padding: 0 20px;
    margin-bottom: 20px;

    > .grid {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: 18px;
      > .grid-item {
        flex: 1 0 calc((100% - 25px * 2) / 3);
        text-align: center;
        font-size: 16px;
        > .img-wrapper {
          width: 100px;
          height: 100px;
          background-color: #e5e5e5;
          border-radius: 50px;
          margin-bottom: 6px;
          overflow: hidden;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          > img {
            width: 118px;
            height: 118px;
            object-fit: contain;
            transform: translateY(18px);
          }
        }
        &:hover {
          cursor: pointer;
        }
      }

      > .button {
        border-radius: 14px;
        padding: 6px;
        text-align: center;
        border: solid 1px #848484;
        color: #848484;
        flex: 1 0 calc((100% - 18px * 2) / 3);
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .title {
    font-size: 20px;
    padding: 16px 0;
  }
`;

export default function CategoryMenu(props) {
  const {visible, setVisible} = props;

  return (
    <Modal visible={visible} onClose={() => setVisible(false)}>
      <CategoryMenuContent {...props} />
    </Modal>
  );
}
