import React from 'react';
import * as Ant from 'antd';
import AdminSelectCouponTemplateWidget from '../../Generators/AdminResource/AdminSelectCouponTemplateWidget';
import AdminSelectProductWidget from '../../Generators/AdminResource/AdminSelectProductNameWidget';
import AdminSelectVariantGroupWidget from '../../Generators/AdminResource/AdminSelectVariantGroupWidget';
import AdminArrayTable from '../../Components/AdminArrayTable';
import {useOutlet} from 'reconnect.js';

/*
{
  drax_box: [
    {
      "award_type": "COUPON",
      "award": {
        "coupon_template": "666809138fe8bceb7d321953"
      },
      "percentage": 10
    },
    {
      "award_type": "STAMP",
      "award": {
        "stamp_count": 1,
        "stamp_event": "6618d94b18740a193028f95e",
        "coupon_template": ""
      },
      "percentage": 10
    },
  ],
  costed_draw_box: [
    {
      award_type: 'COUPON',
      award: {coupon_template: '666809138fe8bceb7d321953'},
      percentage: 100,
    },
  ],
  jackpot_settings: {
    context: [{date: '2024-09-26', coupon_template: '65a0e808eb62103f005900f3', index: 1}],
  },
  included_products: ['早安香蕉'],
  included_variants: ['超值F(森永牛奶糖可頌&中杯紅茶)']
}
*/

export default function Raffle({record, setRecord}) {
  const setDrawBox = (boxName, idx, data) => {
    let draw_box = [...(record[boxName] || [])];
    draw_box[idx] = {...draw_box[idx], ...data};
    setRecord((prev) => ({...prev, [boxName]: draw_box}));
  };

  const [coupon_templates] = useOutlet('coupon_templates');

  return (
    <React.Fragment>
      <h2>進階設定</h2>

      <AdminArrayTable
        dataSource={record?.jackpot_settings?.context || []}
        keyExtractor={(record) => record.date}
        columns={[
          {
            title: '大獎啟動日期',
            span: 6,
            render: (_, item, idx) => (
              <input
                type="date"
                value={item.date}
                onChange={(e) => {
                  const value = e.target.value;
                  let next = [...record.jackpot_settings.context];
                  next[idx] = {...item, date: value || null};
                  setRecord((prev) => ({
                    ...prev,
                    jackpot_settings: {
                      ...prev.jackpot_settings,
                      context: next,
                    },
                  }));
                }}
              />
            ),
          },
          {
            title: '大獎獎品',
            span: 10,
            render: (_, item, idx) => (
              <AdminSelectCouponTemplateWidget
                value={item.coupon_template}
                onChange={(coupon_template) => {
                  let next = [...record.jackpot_settings.context];
                  next[idx] = {...item, coupon_template};
                  setRecord((prev) => ({
                    ...prev,
                    jackpot_settings: {
                      ...prev.jackpot_settings,
                      context: next,
                    },
                  }));
                }}
              />
            ),
          },
          {
            title: '消費順序',
            span: 6,
            render: (_, item, idx) => (
              <Ant.Input
                value={item.index}
                onChange={(e) => {
                  const value = parseInt(e.target.value || 0);
                  let next = [...record.jackpot_settings.context];
                  next[idx] = {...item, index: value};
                  setRecord((prev) => ({
                    ...prev,
                    jackpot_settings: {
                      ...prev.jackpot_settings,
                      context: next,
                    },
                  }));
                }}
              />
            ),
          },
        ]}
        defaultRecord={{
          date: new Date().toLocaleDateString('sv'),
          index: 1,
        }}
        onChange={(values) => {
          console.log('values', values);

          setRecord((prev) => ({
            ...prev,
            jackpot_settings: {
              ...prev.jackpot_settings,
              context: values,
            },
          }));
        }}
      />

      <AdminArrayTable
        dataSource={record['included_products'] || []}
        columns={[
          {
            title: '條件商品 (無活動商品可以不設定)',
            span: 22,
            render: (_, item, idx) => (
              <AdminSelectProductWidget
                value={item}
                onChange={(value) => {
                  const included_products = [...record['included_products']];
                  included_products[idx] = value;
                  setRecord((prev) => ({...prev, included_products}));
                }}
              />
            ),
          },
        ]}
        defaultRecord={''}
        onChange={(values) => {
          console.log('values', values);

          setRecord((prev) => ({...prev, included_products: values}));
        }}
      />

      <AdminArrayTable
        dataSource={record['included_variants'] || []}
        columns={[
          {
            title: '條件商品選項 (無活動商品可以不設定)',
            span: 22,
            render: (_, item, idx) => (
              <Ant.Input
                value={item}
                onChange={(e) => {
                  let value = e.target.value;
                  const included_variants = [...record['included_variants']];
                  included_variants[idx] = value;
                  setRecord((prev) => ({...prev, included_variants}));
                }}
              />
            ),
          },
        ]}
        defaultRecord={''}
        onChange={(values) => {
          console.log('values', values);

          setRecord((prev) => ({...prev, included_variants: values}));
        }}
      />

      {['draw_box', 'costed_draw_box'].map((boxName, index) => (
        <React.Fragment>
          <h2>{index === 0 ? '抽獎箱' : '花點數的抽獎箱'}</h2>

          {boxName === 'costed_draw_box' && (
            <Ant.Row>
              <Ant.Col span={2} className="label">
                消耗點數
              </Ant.Col>
              <Ant.Col span={22}>
                <Ant.Input
                  type="number"
                  value={record.cost_points}
                  onChange={(e) => {
                    const value = parseInt(e.target.value) || 0;
                    setRecord((prev) => ({...prev, cost_points: value}));
                  }}
                />
              </Ant.Col>
            </Ant.Row>
          )}

          <AdminArrayTable
            key={boxName}
            dataSource={record[boxName] || []}
            columns={[
              {
                title: '贈品類型',
                span: 6,
                render: (_, item, idx) => (
                  <Ant.Radio.Group
                    value={item.award_type}
                    onChange={(e) => {
                      let value = e.target.value;
                      setDrawBox(boxName, idx, {award_type: value});
                    }}>
                    <Ant.Space direction="vertical">
                      <Ant.Radio value="COUPON">COUPON</Ant.Radio>
                      <Ant.Radio value="STAMP">STAMP</Ant.Radio>
                    </Ant.Space>
                  </Ant.Radio.Group>
                ),
              },
              {
                title: '贈品細項',
                span: 10,
                render: (_, item, idx) =>
                  item.award_type === 'COUPON' ? (
                    <div>
                      <div>優惠券樣板</div>
                      <AdminSelectCouponTemplateWidget
                        value={item.award.coupon_template}
                        onChange={(coupon_template) => {
                          setDrawBox(boxName, idx, {
                            award: {...item.award, coupon_template},
                          });
                        }}
                      />
                    </div>
                  ) : item.award_type === 'STAMP' ? (
                    <div style={{flex: 1}}>
                      <div>印花數量</div>
                      <Ant.Input
                        type="number"
                        min={0}
                        value={item.award.stamp_count}
                        onChange={(e) => {
                          let value = parseInt(e.target.value);
                          setDrawBox(boxName, idx, {
                            award: {...item.award, stamp_count: value},
                          });
                        }}
                      />

                      <div>活動編號</div>
                      <Ant.Input
                        placeholder="請由該活動複製，貼在此欄位"
                        value={item.award.stamp_event}
                        onChange={(e) => {
                          let value = e.target.value;
                          setDrawBox(boxName, idx, {
                            award: {...item.award, stamp_event: value},
                          });
                        }}
                      />
                    </div>
                  ) : null,
              },
              {
                title: `獲獎機率（%）`,
                span: 6,
                render: (_, item, idx) => (
                  <Ant.Input
                    type="number"
                    min={0}
                    value={item.percentage}
                    onChange={(e) => {
                      let value = parseInt(e.target.value);
                      setDrawBox(boxName, idx, {percentage: value});
                    }}
                  />
                ),
              },
            ]}
            defaultRecord={{
              award_type: 'COUPON',
              award: {coupon_template: ''},
              percentage: 10,
            }}
            onChange={(values) =>
              setRecord((prev) => ({...prev, [boxName]: values}))
            }
          />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}
