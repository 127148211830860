import React from 'react';
import styled from 'styled-components';
import * as AppActions from '../../AppActions';

export default function SiteFooter() {
  return (
    <Wrapper>
      <div className="content">
        {/* 
          <div className="links">
            <h3>餐點</h3>
            <a href="/#">推薦餐點</a>
            <a href="/#">特色餐點</a>
            <a href="/#">漢堡吐司</a>
            <a href="/#">飲料</a>
          </div>
          */}

        <div className="socials">
          <a href="https://www.facebook.com/layaburger2002?locale=tw_TW">
            <img src="/images/icon-fb.png" alt="facebook" />
          </a>
          <a href="https://instagram.com/layaburger?igshid=YmMyMTA2M2Y=">
            <img src="/images/icon-ig.png" alt="instagram" />
          </a>
        </div>

        {/* 
          <div className="downloads">
            <h3>下載拉亞漢堡</h3>
            <a href="/#">
              <img
                className="apple"
                src="/images/app-store.svg"
                alt="app-store"
              />
            </a>
            <a href="/#">
              <img
                className="google"
                src="/images/google-play.png"
                alt="app-store"
              />
            </a>
          </div>
          */}

        <a
          style={{color: '#fff', marginBottom: 10}}
          href="/article/?id=632ff480df20d0c188bcfd16"
          onClick={(e) => {
            e.preventDefault();
            AppActions.navigate('/article/?id=632ff480df20d0c188bcfd16');
          }}>
          隱私權保護聲明
        </a>

        <div style={{color: '#fff'}}>© 2025 拉亞漢堡 版權所有</div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.footer`
  background-color: #80c1ae;
  padding: 20px 0;

  & > .content {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .socials {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    & > a {
      display: flex;
      align-items: center;

      & > img {
        display: block;
        width: 28px;
        height: 28px;
        border-radius: 14px;
        object-fit: contain;
        margin-right: 10px;
      }
    }
  }
`;
