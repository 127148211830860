import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {Button, Input, message} from 'antd';
import BoolVariants from './BoolVariants';
import SingleVariant from './SingleVariant';
import DrinksVariant from './DrinksVariant';
import {getDefaultDrinkVariants} from './DrinkVariantDetail';
import {ArrowLeftShort} from '@styled-icons/bootstrap/ArrowLeftShort';
import {CheckCircle} from '@styled-icons/bootstrap/CheckCircle';
import * as AppActions from '../../AppActions';
import * as LayaCartUtil from '../../Utils/LayaCartUtil';
import {getItemQtyInCart} from '../../Utils/CartUtil';
import {
  ProductPromoTags,
  ProductAttrTags,
  ProductHashTags,
} from '../ProductItem/ProductMisc';
import {useSelectedStore} from '../SelectStoreModal';
import * as CatUtil from '../../Utils/CatUtil';
import * as PriceUtil from '../../Utils/PriceUtil';
import Theme from '../../Theme';
import AddonVariant from './AddonVariant';
import {logAddToCart} from '../../Hooks/useGoogleAnalytics';

/*
套餐邏輯
{set: 'none'} = 不升級套餐
{set: '無'} = 套餐含飲品（必選）
> !set || set === 'none' 沒有飲料
*/

export default function ProductDetail(props) {
  const [landing] = useOutlet('landing');

  const store = useSelectedStore();
  const [mealCategories] = useOutlet('mealCategories');
  const {product, allProducts, cartItemIdx, cartItem, cat = ''} = props;
  const [selectedVariants, setSelectedVariants] = React.useState(
    cartItem ? cartItem.variants : {},
  );
  const [selectedDrink, setSelectedDrink] = React.useState(
    cartItem ? cartItem.drink : null,
  );
  const [selectedAddon, setSelectedAddon] = React.useState(
    cartItem ? cartItem.add_on : undefined,
  );
  const [allDrinks, setAllDrinks] = React.useState(null);
  const [qty, setQty] = React.useState(cartItem ? cartItem.qty : 1);
  const [itemTotalPrice, setItemTotalPrice] = React.useState(null);
  const [note, setNote] = React.useState(cartItem ? cartItem.note : '');
  const [cart] = useOutlet('LayaCart');
  const itemQtyInCart = getItemQtyInCart(cart, product);
  const [variantGroups] = useOutlet('variantGroups');

  const setVariant = (product.variants || []).find((v) => v.name === 'set');
  const selectedSet = selectedVariants.set;
  const allowedL2CatsMap = React.useMemo(() => {
    const allowedL2Cats = (store?.meals || []).reduce((acc, name) => {
      const mealCat = (mealCategories || []).find((m) => m.name === name);
      if (mealCat?.product_categories?.length > 0) {
        acc = [...acc, ...mealCat?.product_categories];
      }
      return acc;
    }, []);

    return allowedL2Cats.reduce((acc, c) => {
      acc[c] = true;
      return acc;
    }, {});
  }, [store, mealCategories]);
  const {catL1} = CatUtil.getCategoryOfProduct(product, cat);

  const {
    singleVariants,
    boolVariants,
    invalidVariants,
    variantGroup,
  } = React.useMemo(() => {
    const invalidVariants = product?.$invalidVariants || [];
    const variantGroup = variantGroups.find((vg) => vg.name === product?.group);

    return {
      singleVariants: (product?.variants || [])
        .filter((v) => v.type === 'single')
        .map((x) => ({
          ...x,
          choices: x.choices.map((c) => ({
            ...c,
            $invalid: invalidVariants.includes(c.name),
          })),
        })),
      boolVariants: (product?.variants || [])
        .filter((v) => v.type === 'bool')
        .map((v) => ({...v, $invalid: invalidVariants.includes(v.name)})),
      invalidVariants,
      variantGroup,
    };
  }, [product, variantGroups]);

  const setDefaultDrink = React.useCallback(
    (allDrinks) => {
      let _defaultDrinkName = '紅茶';
      if (product.name.indexOf('兒童') > -1) {
        _defaultDrinkName = '蘋果茶';
      } else if (product.name === '牛奶糖奶茶組') {
        _defaultDrinkName = '森永牛奶糖奶茶';
      } else if (product.name === '天空抹茶拿鐵組') {
        _defaultDrinkName = '天空抹茶拿鐵';
      }
      const _defaultDrink = allDrinks.find((d) => d.name === _defaultDrinkName);
      if (_defaultDrink) {
        setSelectedDrink({
          product: _defaultDrink,
          variants: getDefaultDrinkVariants(_defaultDrink),
        });
      }
    },
    [product],
  );

  React.useEffect(() => {
    async function setup() {
      const defaultSelectedVariants = {};
      if (!cartItem) {
        for (const v of singleVariants) {
          if (Array.isArray(v.choices)) {
            let defaultChoice;
            if (v.default_index) {
              defaultChoice = v.choices[v.default_index];
            }
            if (
              !defaultChoice ||
              (defaultChoice && !defaultChoice.name && defaultChoice.$invalid)
            ) {
              defaultChoice = v.choices.find((c) => !!c?.name && !c.$invalid);
            }
            defaultSelectedVariants[v.name] = defaultChoice.name;
          }
        }
        setSelectedVariants(defaultSelectedVariants);
        setQty(1);
      }

      if (
        !product.labels.find(
          (l) =>
            l.indexOf('飲品') > -1 ||
            l.indexOf('咖啡') > -1 ||
            l.indexOf('小點') > -1 ||
            (l.indexOf('輕食') > -1 && product.name.indexOf('兒童') === -1),
        )
      ) {
        let _allDrinks = allProducts
          .filter((p) => {
            for (const label of p.labels || []) {
              if (label.indexOf('飲品') > -1) {
                return true;
              }
            }
            return false;
          })
          .filter((d) => {
            for (const c of d.labels) {
              if (allowedL2CatsMap[c]) {
                return true;
              }
            }
            return false;
          })
          .filter((d) => {
            return product.name === '牛奶糖奶茶組'
              ? d.name === '森永牛奶糖奶茶'
              : product.name === '天空抹茶拿鐵組'
              ? d.name === '天空抹茶拿鐵'
              : true;
          });

        if (landing.drink_sorting?.sorting) {
          _allDrinks.sort((a, b) => {
            const negative = landing.drink_sorting.sorting[0] === '-';
            const sorting = negative
              ? landing.drink_sorting.sorting.slice(1)
              : landing.drink_sorting.sorting;

            function compByKey(key) {
              if (a[key] === b[key]) {
                return 0;
              } else if (a[key] > b[key]) {
                return 1;
              } else {
                return -1;
              }
            }

            return compByKey(sorting) * (negative ? -1 : 1);
          });
        }

        const topDrinks = [];
        if (Array.isArray(landing.drink_sorting?.top_drinks)) {
          for (const drinkId of landing.drink_sorting.top_drinks) {
            const idx = _allDrinks.findIndex((d) => d.id === drinkId);
            if (idx > -1) {
              topDrinks.push(_allDrinks[idx]);
              _allDrinks.splice(idx, 1);
            }
          }
        }

        _allDrinks = [...topDrinks, ..._allDrinks];
        setAllDrinks(_allDrinks);

        if (
          !cartItem &&
          !!defaultSelectedVariants.set &&
          defaultSelectedVariants.set !== 'none'
        ) {
          setDefaultDrink(_allDrinks);
        }
      } else {
        setAllDrinks([]);
      }
    }

    setup();
  }, [
    product,
    singleVariants,
    cartItem,
    allowedL2CatsMap,
    allProducts,
    setDefaultDrink,
  ]);

  React.useEffect(() => {
    async function calcItemPrice() {
      const itemConfig = {
        product_id: product?.id,
        qty,
        variants: selectedVariants || [],
      };

      if (selectedDrink && selectedDrink.product) {
        itemConfig.drink = {
          product_id: selectedDrink.product.id,
          variants: selectedDrink.variants,
        };
      }

      if (selectedAddon) {
        itemConfig.add_on = {
          product_id: selectedAddon.product.id,
          variants: selectedAddon.variants || {},
        };
      }

      AppActions.setLoading(true, {visible: false});
      try {
        let items = [itemConfig];
        const calcResult = await AppActions.calcItemPrice(items);
        setItemTotalPrice(calcResult.total);
      } catch (ex) {
        console.warn(ex);
      }
      AppActions.setLoading(false);
    }

    if (product && Array.isArray(allDrinks)) {
      calcItemPrice();
    }
  }, [product, selectedVariants, selectedDrink, qty, allDrinks, selectedAddon]);

  if (!product) {
    return null;
  }

  const imgUrl = (product.images || [])[0]?.expected_url || '/images/logo.png';

  async function onConfirm() {
    if (selectedSet && selectedSet !== 'none') {
      if (!selectedDrink) {
        alert('請選擇套餐飲料!');
        return;
      }
    }

    AppActions.setLoading(true);
    try {
      if (!cartItem) {
        await LayaCartUtil.addItem({
          product,
          qty,
          variants: selectedVariants,
          drink: selectedDrink,
          add_on: selectedAddon,
          note,
        });

        message.success('成功加入購物車!');

        logAddToCart({items: [{product, qty}], value: itemTotalPrice});
      } else {
        console.log('editing cart item...');
        await LayaCartUtil.editItem(cartItemIdx, {
          product,
          qty,
          variants: selectedVariants,
          drink: selectedDrink,
          add_on: selectedAddon,
          note,
        });

        message.success('成功修改購物車!');
      }
    } catch (ex) {
      console.warn(ex);
      if (ex?.response?.error === 'item_over_limit') {
        message.error('超過購物車數量上限，請分次下單');
      } else {
        message.error('發生錯誤, 請稍後再試一次');
      }
    }
    AppActions.setLoading(false);

    if (cartItem) {
      AppActions.navigate('/checkout/');
    } else {
      AppActions.goBack();
    }
  }

  return (
    <Wrapper>
      <div className="content" style={{backgroundColor: catL1?.lighttone}}>
        {itemQtyInCart > 0 && (
          <div className="qty-in-cart">
            <CheckCircle color="white" size={16} />
            <span className="text">已加入購物車({itemQtyInCart})</span>
          </div>
        )}

        <img src={imgUrl} alt="meal" />

        <div className="tag-bar">
          <ProductPromoTags product={product} />
          <ProductHashTags product={product} />
          <ProductAttrTags product={product} />
        </div>

        <h2>{product.name}</h2>

        <h3 style={{fontSize: 18}}>{product.english_name || ''}</h3>

        <div className="description">{product.description || ''}</div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}>
          <div
            style={{
              fontSize: 20,
              color: '#aaa',
              textDecorationLine: 'line-through',
            }}>
            {(product.original_price &&
              product.original_price !== product.price &&
              `${PriceUtil.displayPrice(product.original_price, store, {
                multipleOf: variantGroup?.price_delta_multiple_of,
              })}`) ||
              ''}
          </div>
          <div
            style={{
              marginLeft: 6,
              fontSize: 24,
              fontWeight: 'bold',
              color: Theme.colors.red,
            }}>
            {PriceUtil.displayPrice(product.price, store, {
              multipleOf: variantGroup?.price_delta_multiple_of,
            })}
          </div>
        </div>

        <div
          className="back-to-list"
          onClick={() => {
            if (!cartItem) {
              AppActions.goBack();
            } else {
              AppActions.navigate('/checkout/');
            }
          }}>
          <ArrowLeftShort size={28} color="black" />
          <div>{cartItem ? '返回購物車' : '返回餐飲列表'}</div>
        </div>
      </div>

      {singleVariants.map((v) => {
        return (
          <SingleVariant
            key={v.name}
            variant={v}
            selected={selectedVariants[v.name]}
            setSelected={(selected) => {
              setSelectedVariants({...selectedVariants, [v.name]: selected});

              if (v.name === 'set' && selected !== 'none' && !selectedDrink) {
                setDefaultDrink(allDrinks);
              } else if (
                v.name === 'set' &&
                selected === 'none' &&
                !!selectedDrink
              ) {
                setSelectedDrink(null); // remove
              }
            }}
            catL1={catL1}
          />
        );
      })}

      <BoolVariants
        variants={boolVariants}
        selectedVariants={selectedVariants}
        setSelectedVariants={setSelectedVariants}
        catL1={catL1}
      />

      {(allDrinks || []).length > 0 && (
        <DrinksVariant
          drinks={allDrinks}
          selected={selectedDrink}
          setSelected={setSelectedDrink}
          selectedSet={selectedSet}
          setVariant={setVariant}
          catL1={catL1}
        />
      )}

      {product.name.indexOf('咖啡') === -1 &&
        (product.name.indexOf('拿鐵') === -1 ||
          product.name.indexOf('拿鐵組') > -1) &&
        product.name.indexOf('美式咖啡') === -1 &&
        !(product.labels || []).find(
          (l) => l.indexOf('飲品') > -1 || l.indexOf('咖啡') > -1,
        ) && (
          <AddonVariant
            allProducts={allProducts}
            selected={selectedAddon}
            setSelected={setSelectedAddon}
            catL1={catL1}
          />
        )}

      <div style={{}}>
        <h2 style={{marginBottom: 8}}>訂單備註</h2>
        <Input.TextArea
          style={{width: '100%', height: 100}}
          value={note}
          placeholder="限25字以內"
          onChange={(e) => {
            let _note = e.target.value;
            if (_note.length <= 25) {
              setNote(_note);
            }
          }}
        />
      </div>

      <div
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100vw',
          backgroundColor: 'white',
          boxShadow: '0px 3px 6px rgba(0,0,0,0.66)',
          borderTopLeftRadius: 30,
          borderTopRightRadius: 30,
        }}>
        <div
          style={{
            maxWidth: 768,
            margin: '0 auto',
            padding: '20px 20px',
          }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 10,
            }}>
            <div style={styles.qtyInput}>
              <div
                style={styles.qtyInputMinus}
                onClick={() => {
                  if (qty > 1) {
                    setQty(qty - 1);
                  }
                }}>
                <div style={{fontSize: 24}}>-</div>
              </div>

              <div style={styles.qtyValue}>
                <div style={{fontSize: 18}}>{qty}</div>
              </div>

              <div
                style={styles.qtyInputPlus}
                onClick={() => {
                  setQty(qty + 1);
                }}>
                <div style={{fontSize: 24}}>+</div>
              </div>
            </div>

            <div style={{flex: 1}} />

            {itemTotalPrice !== null && (
              <div style={styles.priceBox}>
                <div style={styles.priceText}>{itemTotalPrice}</div>
              </div>
            )}
          </div>

          <Button
            type="primary"
            size="large"
            style={{width: '100%'}}
            onClick={onConfirm}>
            {cartItem ? '更新' : '加入'}購物車
          </Button>
        </div>
      </div>
    </Wrapper>
  );
}

const styles = {
  qtyInput: {
    display: 'flex',
    alignItems: 'center',
    alignItems: 'stretch',
    height: 40,
    backgroundColor: '#ece9e8',
    borderRadius: 20,
  },
  qtyInputMinus: {
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  qtyValue: {
    width: 80,
    marginHorizontal: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  qtyInputPlus: {
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  priceBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  priceText: {
    fontSize: 32,
    fontWeight: 'bold',
  },
};

const Wrapper = styled.div`
  padding: 20px 10px;

  & > .content {
    position: relative;
    padding: 45px 20px 10px 20px;
    display: flex;
    flex-direction: column;
    border-radius: 14px;
    margin-bottom: 20px;
    box-shadow: ${Theme.shadow};

    & > .back-to-list {
      position: absolute;
      top: 20px;
      left: 12px;
      display: flex;
      align-items: center;
      cursor: pointer;

      & > *:last-child {
        margin-right: 5px;
        font-size: 1.1rem;
        line-height: 28px;
      }
    }

    & > .qty-in-cart {
      align-self: flex-end;
      padding: 6px 12px;
      background-color: orange;
      opacity: 0.75;
      border-radius: 32px;
      display: flex;
      align-items: center;

      & > .text {
        margin-left: 5px;
        color: white;
      }
    }

    & > img {
      width: 80%;
      object-fit: contain;
      align-self: center;
      margin-bottom: 15px;
    }

    & > h2 {
      font-size: 2rem;
      font-weight: 500;
    }

    & > .description {
      color: #888;
      font-size: 1rem;
    }

    & .tag-bar {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      & > * {
        margin-right: 5px;
        margin-bottom: 5px;
      }
    }
  }
`;
