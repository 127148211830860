import React from 'react';
import styled from 'styled-components';
import {Modal, Button, Input} from 'antd';
import * as AppActions from '../../AppActions';
import StoreItem from '../../Components/StoreItem';
import {useOutlet} from 'reconnect.js';
import * as LocalCartUtil from '../../Utils/LocalCartUtil';
import * as StoreUtil from '../../Utils/StoreUtil';
import {
  useSelectedStore,
  setSelectedStore,
  showSelectStoreModal,
} from './helper';
import {unsetSelectedTime} from '../SelectOrderTypeModal/helper';

export default function SelectStoreModal(props) {
  const [innerState, setInnerState] = useOutlet('SelectStore');
  const [_stores, setStores] = React.useState([]);
  const [localSelectedStore, setLocalSelectedStore] = React.useState(null);
  const {visible, store} = innerState;
  const [keyword, setKeyword] = React.useState('');

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        setStores(await AppActions.fetchStores());
      } catch (ex) {}
      AppActions.setLoading(false);
    }

    fetchData();
  }, []);

  React.useEffect(() => {
    if (!visible) {
      setKeyword('');
    }
  }, [visible]);

  function selectStore(store) {
    setInnerState({...innerState, store, visible: false});
    LocalCartUtil.setStore(store);
  }

  const stores = _stores.filter((s) => {
    if (!keyword) {
      return true;
    } else {
      return s.name.indexOf(keyword) > -1;
    }
  });

  return (
    <Modal
      visible={visible}
      onCancel={() => setInnerState({...innerState, visible: false})}
      bodyStyle={{
        padding: 0,
        borderRadius: 8,
        height: '80%',
        maxHeight: '70vh', // 'calc(100vh - 200px)',
        overflowY: 'scroll',
      }}
      style={{padding: 0, borderRadius: 8, top: 50}}
      footer={
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <Button
            size="large"
            style={{flex: 1, marginRight: 15}}
            onClick={() => {
              selectStore(null);
            }}>
            清除
          </Button>
          <Button
            size="large"
            type="primary"
            style={{flex: 1}}
            disabled={!localSelectedStore}
            onClick={() => {
              selectStore(localSelectedStore);
              unsetSelectedTime();
            }}>
            確認
          </Button>
        </div>
      }
      destroyOnClose>
      <Wrapper>
        <div className="search-bar">
          <Input value={keyword} onChange={(e) => setKeyword(e.target.value)} />
          <Button type="link" onClick={() => setKeyword('')}>
            清除
          </Button>
        </div>

        <div className="store-list">
          {stores.map((s) => {
            return (
              <StoreItem
                selected={s.id === localSelectedStore?.id}
                key={s.id}
                store={s}
                withChevronRight={false}
                onClick={() => {
                  setLocalSelectedStore(s);
                }}
              />
            );
          })}
        </div>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div`
  padding: 30px;

  & > .search-bar {
    width: 100%;
    background-color: white;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    & > *:first-child {
      width: 60%;
      margin-right: 10px;
    }
  }

  & .store-list {
    margin-top: 10px;
  }
`;

export {useSelectedStore, setSelectedStore, showSelectStoreModal};
