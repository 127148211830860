import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {useOutlet} from 'reconnect.js';
import {Button, Tooltip, message, Input} from 'antd';
import Config from '../../../data.json';
import * as PermissionUtil from '../../Utils/PermissionUtil';

export default function AdminStoreTopSection(props) {
  const {context, path} = props;
  const {
    errors,
    formSpec,
    instance,
    position,
    values,
    setValues,
    extValues,
    setExtValues,
  } = context;
  const store_id = instance?.id;
  const [secret, setSecret] = useState();
  const [promotion, setPromotion] = useState();
  const [{loading}] = useOutlet('loading');
  const [user] = useOutlet('user');

  const getStorePromotion = useCallback(async () => {
    if (store_id) {
      try {
        let resp = await JStorage.fetchOneDocument('store_promotion', {
          store: store_id,
        });
        if (resp.status > 400) {
          throw new Error('找不到 store promotion');
        }

        setPromotion(resp);
        return resp;
      } catch (err) {
        console.warn(err);
      }
    }
  }, [store_id]);

  const getStoreSecret = useCallback(async () => {
    if (store_id) {
      try {
        let resp = await JStorage.fetchOneDocument('store_secret', {
          store_id,
        });
        if (resp.status > 400) {
          throw new Error('找不到 store promotion');
        }
        setSecret(resp);
        return resp;
      } catch (err) {
        console.warn(err);
      }
    }
  }, [store_id]);

  useEffect(() => {
    (async () => {
      AppActions.setLoading(true);
      await Promise.all([getStoreSecret(), getStorePromotion()]);
      AppActions.setLoading(false);
    })();
  }, [getStoreSecret, getStorePromotion]);

  return (
    <Wrapper>
      <Button
        type="link"
        loading={loading}
        onClick={async () => {
          let item = secret;
          if (!item) {
            item = await getStoreSecret();
          }
          if (!item) {
            if (PermissionUtil.writable({path, user})) {
              window.open(
                `/admin/store_secrets/?action=create&store_id=${store_id}`,
                '_blank',
              );
            } else {
              message.error('權限不足');
            }
          } else {
            window.open(
              `/admin/store_secrets/?action=detail&id=${item?.id}`,
              '_blank',
            );
          }
        }}>
        分店密鑰
      </Button>
      <div className="divider" />
      <Button
        type="link"
        loading={loading}
        onClick={async () => {
          let item = promotion;
          if (!item) {
            item = await getStorePromotion();
          }
          if (!item) {
            if (PermissionUtil.writable({path, user})) {
              window.open(
                `/admin/store_promotions/?action=create&store_id=${store_id}`,
                '_blank',
              );
            } else {
              message.error('權限不足');
            }
          } else {
            window.open(
              `/admin/store_promotions/?action=detail&id=${item?.id}`,
              '_blank',
            );
          }
        }}>
        促銷活動
      </Button>
      <div className="divider" />
      <Button
        type="link"
        target="_blank"
        href={`/admin/pos_stock_records/?store_id=${store_id}`}>
        POS庫存更新
      </Button>

      <div className="divider" />
      <Button
        type="link"
        target="_blank"
        href={`/admin/history/?query=${encodeURIComponent(
          JSON.stringify({
            $or: [
              {collection: 'store', document_id: store_id},
              ...(promotion
                ? [{collection: 'store_promotion', document_id: promotion.id}]
                : []),
              ...(secret
                ? [{collection: 'store_secret', document_id: secret.id}]
                : []),
            ],
          }),
        )}`}>
        歷程紀錄
      </Button>
      <div className="divider" />
      <Button
        type="link"
        target="_blank"
        href={`/admin/store/coupon?store_id=${store_id}`}>
        分店優惠券
      </Button>
      {/* <div className="divider" /> */}
      {/* <Button
        type="link"
        target="_blank"
        href={`/admin/coupon_instances/?store_id=${store_id}`}>
        客戶優惠券
      </Button> */}

      <React.Fragment>
        <div className="divider" />
        <Button
          type="link"
          target="_blank"
          href={`/admin/store_event_settings/?store_id=${store_id}`}>
          分店活動設定
        </Button>{' '}
      </React.Fragment>

      {(Config.stage === 'dev' || Config.stage === 'stg') && (
        <React.Fragment>
          <div className="divider" />

          <Button
            type="link"
            target="_blank"
            href={`/admin/store_pos_configs/?action=detail&id=${store_id}`}>
            REVTEL POS設定
          </Button>
        </React.Fragment>
      )}

      {/**/}
    </Wrapper>
  );
}

export function AdminStoreBottomSection(props) {
  const {context, path} = props;
  const {
    errors,
    formSpec,
    instance,
    position,
    values,
    setValues,
    extValues,
    setExtValues,
  } = context;
  const store_id = instance?.id;
  const [user] = useOutlet('user');

  return null;

  // return PermissionUtil.checkFunction(
  //   user,
  //   PermissionUtil.permissions.store_price_delta_read,
  // ) ? (
  //   <div style={{marginBottom: 24}}>
  //     <div style={{paddingBottom: 8, height: 32}}>特殊分店加價</div>
  //     <Input
  //       type="number"
  //       value={values.price_delta}
  //       onChange={(e) => {
  //         let price_delta = parseInt(e.target.value) || 0;
  //         setValues((prev) => ({
  //           ...prev,
  //           price_delta,
  //         }));
  //       }}
  //     />
  //     <div style={{color: '#00000073'}}>特殊店之所有餐點加價</div>
  //   </div>
  // ) : null;
}

const Wrapper = styled.div`
  display: flex;
  border: solid 1px #ddd;
  padding: 10px;
  border-radius: 30px;
  margin-bottom: 20px;

  & .divider {
    background-color: #ddd;
    width: 1px;
  }
`;
