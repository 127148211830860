import React from 'react';
import styled from 'styled-components';
import {Pencil} from '@styled-icons/boxicons-solid/Pencil';
import {Trash} from '@styled-icons/boxicons-regular/Trash';
import Cart from '../../Components/Cart';
import * as LayaCartUtil from '../../Utils/LayaCartUtil';
import * as AppActions from '../../AppActions';
import * as Antd from 'antd';
import {useOutlet, getOutlet} from 'reconnect.js';
import {
  useSelectedStore,
  setSelectedStore,
} from '../../Components/SelectStoreModal';
import {
  useSelectedOrderType,
  unsetSelectedData,
  showSelectOrderTypeModal,
} from '../../Components/SelectOrderTypeModal';
import * as StoreUtil from '../../Utils/StoreUtil';
import {getOrderTypeDisplayStr} from './helper';
import Theme from '../../Theme';
import CheckoutCTA from '../../Components/CheckoutCTA';
import qs from 'query-string';
import {logBeginCheckout, logCustomEvent} from '../../Hooks/useGoogleAnalytics';

export default function CartPage(props) {
  const [cart] = useOutlet('LayaCart');
  const [cartPrice] = useOutlet('LayaCartPrice');
  const [cartExtra] = useOutlet('LayaCartExtra');
  const store = useSelectedStore();
  const orderType = useSelectedOrderType();
  const params = qs.parse(props.location.search);

  React.useEffect(() => {
    (async () => {
      try {
        await LayaCartUtil.refreshCart();
      } catch (err) {
        console.warn(err);
      }
    })();
  }, [store]);

  React.useEffect(() => {
    if (params?.payment_status === 'failed' && typeof window !== 'undefined') {
      logCustomEvent('payment_failure', {
        payment_type: params?.payment_type,
        failure_reason: 'unknown',
      });

      window.alert(
        '付款失敗\n請別擔心，您的訂單還在等您！\n您可以更換信用卡或使用其他支付方式，我們很期待為您服務！\n\n*小提醒：付款失敗可能原因為\n使用到了國外卡、掛失卡、餘額不足、授權驗證碼失敗或信用卡過期等',
      );
    }
  }, [params.payment_status]);

  function changeOrderType() {
    const isTableOrder = !!orderType?.table;

    if (isTableOrder) {
      if (window.confirm('將會清除桌邊點餐設定, 確定要繼續嗎?')) {
        setSelectedStore(null);
        unsetSelectedData();
        showSelectOrderTypeModal(true);
      }
      return;
    }

    showSelectOrderTypeModal(true);
  }

  const [
    validCart,
    invalidReason,
    invalidCartAction,
  ] = LayaCartUtil.validateCart();
  const [
    validCartItems,
    invalidCartItemsReason,
  ] = LayaCartUtil.validateCartItems();

  if (cartExtra.loading) {
    return (
      <Wrapper>
        <div className="content">
          <h3 style={{color: '#888', textAlign: 'center'}}>
            購物車重新計算中, 請稍候...
          </h3>

          <div className="loading">
            <img className="icon" src="/images/logo.png" alt="laya" />
          </div>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="content">
        <div style={{marginBottom: 20}}>
          <div style={{fontSize: 22, marginBottom: 10}}>取餐資訊</div>
          <div
            style={{
              backgroundColor: Theme.colors.bgYellow,
              padding: 15,
              borderRadius: 15,
              boxShadow: '0 2px 4px rgba(0,0,0,0.4)',
            }}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div style={{color: Theme.colors.red, fontSize: 18}}>
                {getOrderTypeDisplayStr({
                  orderType,
                  store,
                  total: cartPrice?.total,
                })}
              </div>
              <div className="edit-button" onClick={changeOrderType}>
                <div style={{color: Theme.colors.main, marginRight: 4}}>
                  編輯
                </div>
                <Pencil size={16} style={{color: Theme.colors.main}} />
              </div>
            </div>

            <div style={styles.field}>
              <div style={{fontSize: 18}}>門市: </div>
              <div style={{fontSize: 18}}>{store?.name || '---'}</div>
            </div>
          </div>
        </div>

        <div style={{marginBottom: 20}}>
          <div
            style={{
              marginBottom: 10,
              display: 'flex',
              alignItems: 'center',
            }}>
            <div style={{fontSize: 22, flex: 1}}>餐點列表</div>
            <div
              onClick={() => AppActions.navigate('/products')}
              style={{
                padding: '6px 12px',
                borderRadius: 32,
                backgroundColor: Theme.colors.main,
                cursor: 'pointer',
                marginRight: 10,
              }}>
              <div style={{fontSize: 16, color: 'white'}}>+ 加購餐點</div>
            </div>
            <div
              style={{
                padding: '6px 12px',
                backgroundColor: '#C3C1BD',
                color: '#fff',
                fontSize: 16,
                borderRadius: 32,
                cursor: 'pointer',
                display: 'flex',
              }}
              onClick={async () => {
                if (window.confirm('確認要清空購物車嗎?')) {
                  AppActions.setLoading(true);
                  try {
                    await LayaCartUtil.clearCart();
                  } catch (ex) {
                    console.warn(ex);
                  }
                  AppActions.setLoading(false);
                }
              }}>
              <img
                src="/images/icon-trash-hover.png"
                style={{width: 15, objectFit: 'contain', marginRight: 6}}
              />
              <div>清空購物車</div>
            </div>
          </div>
          <div
            style={{
              backgroundColor: Theme.colors.bgYellow,
              borderRadius: 15,
              boxShadow: '0 2px 4px rgba(0,0,0,0.4)',
            }}>
            <Cart />
          </div>
        </div>
      </div>

      <div className="cta">
        <div className="content">
          <CheckoutCTA
            label={'下一步'}
            disabled={!validCart || !validCartItems}
            invalidReason={invalidReason || invalidCartItemsReason}
            invalidAction={invalidCartAction}
            onClick={async () => {
              if (
                !getOutlet('LayaCart').getValue().config.time &&
                !StoreUtil.isStoreOpen({store, dt: new Date()})
              ) {
                const result = window.confirm(
                  `${store.name}目前為非營業時間, 確認要繼續嗎?`,
                );
                if (!result) {
                  return;
                }
              }

              logBeginCheckout({value: cartPrice?.total, items: cart.items});

              AppActions.setLoading(true);
              await AppActions.delay(800);
              AppActions.navigate('/checkout/payment/');
              AppActions.setLoading(false);
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
}

const styles = {
  actionBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  field: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px 0',
  },
};

const Wrapper = styled.div`
  & > .content {
    padding: 10px;
    margin: 0 auto;
    max-width: 720px;

    & > .loading {
      & > img.icon {
        margin: 20px auto;
        width: 200px;
        height: 200px;
        object-fit: contain;
        border-radius: 16px;
        overflow: hidden;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.33);
        display: block;
        animation: heartbeat 1s infinite;
      }
    }

    @keyframes heartbeat {
      0% {
        transform: scale(1);
        opacity: 1;
      }

      50% {
        transform: scale(0.92);
        opacity: 0.92;
      }

      100% {
        transform: scale(1);
        opacity: 1;
      }
    }

    & > .cart {
      padding: 10px;

      & > h2 {
        font-size: 2rem;
      }

      & > h3 {
        font-size: 1rem;
        color: #aaa;
      }
    }
  }

  & > .cta {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: white;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    overflow: hidden;
    box-shadow: 0px -2px 6px -2px #555;

    & > .content {
      max-width: 720px;
      margin: 0 auto;
    }
  }

  & .edit-button {
    border-radius: 50px;
    border: 1px solid ${Theme.colors.main};
    padding: 4px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 6px;
  }
`;
