import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {useOutlet} from 'reconnect.js';
import {Button, Tooltip, message, Input} from 'antd';
import Config from '../../../data.json';
import * as PermissionUtil from '../../Utils/PermissionUtil';

export default function AdminStoreTopSection(props) {
  const {context, path} = props;
  const {
    errors,
    formSpec,
    instance,
    position,
    values,
    setValues,
    extValues,
    setExtValues,
  } = context;
  const store_id = instance?.id;
  const name = instance?.name;
  const [{loading}] = useOutlet('loading');
  const [user] = useOutlet('user');

  return (
    <Wrapper>
      <Button
        type="link"
        target="_blank"
        href={`/pos/edit-station?name=${name}&token=${user.token}`}>
        編輯工作站
      </Button>

      <div className="divider" />
      <Button
        type="link"
        target="_blank"
        href={`/pos/edit-product-stocks?name=${name}&token=${user.token}`}>
        編輯庫存
      </Button>
    </Wrapper>
  );
}

export function AdminStorePosBottomSection(props) {
  const {context, path} = props;
  const {
    errors,
    formSpec,
    instance,
    position,
    values,
    setValues,
    extValues,
    setExtValues,
  } = context;
  const store_id = instance?.id;
  const name = instance?.name;
  const [user] = useOutlet('user');

  return (
    <BottomWrapper>
      <table>
        <thead>
          <tr>
            <th>工作站</th>
            <th>是否是主控台</th>
            <th>商品</th>
            <th>貼紙</th>
          </tr>
        </thead>
        <tbody>
          {instance.stations.map((s, idx) => (
            <tr key={idx}>
              <td>{s.name}</td>
              <td>{{normal: '一般工作台', main: '主控台'}[s.type]}</td>
              <td>
                <Button
                  type="link"
                  target="_blank"
                  href={`/pos/edit-station-products?name=${name}&station=${s.name}&token=${user.token}`}>
                  編輯工作站商品
                </Button>
              </td>
              <td>
                <Button
                  type="link"
                  target="_blank"
                  href={`/pos/edit-sticker-products?name=${name}&station=${s.name}&token=${user.token}`}>
                  編輯工作站貼紙商品
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </BottomWrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  border: solid 1px #ddd;
  padding: 10px;
  border-radius: 30px;
  margin-bottom: 20px;

  & .divider {
    background-color: #ddd;
    width: 1px;
  }
`;

const BottomWrapper = styled.div`
  table,
  th,
  td {
    border: solid 1px #bbb;
    border-collapse: collapse;
  }
  th,
  td {
    padding: 8px 12px;
  }
`;
