import React, {useEffect} from 'react';
import * as Ant from 'antd';
import AdminArrayTable from '../../Components/AdminArrayTable';
import AdminSelectProductWidget from '../../Generators/AdminResource/AdminSelectProductNameWidget';
import {useOutlet} from 'reconnect.js';
import RevSingleImageUploader from 'rev.sdk.js/Generic/CustomWidgets/RevSingleImageUploader';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';

export default function AnnualReview({record, setRecord}) {
  const [products, setProducts] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      try {
        let products = await JStorage.fetchAllDocuments('product', {
          public: true,
        });
        setProducts(products);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <React.Fragment>
      <h2>進階設定</h2>

      <Ant.Row>
        <Ant.Col span={2} className="label">
          優惠券代碼
        </Ant.Col>
        <Ant.Col span={6}>
          <Ant.Input
            value={record.coupon_code}
            onChange={(e) =>
              setRecord((prev) => ({...prev, coupon_code: e.target.value}))
            }
          />
        </Ant.Col>
        <Ant.Col span={2} className="label">
          美味時刻預設圖片
        </Ant.Col>
        <Ant.Col span={6}>
          <RevSingleImageUploader
            value={record.stage_6?.image}
            onChange={(value) =>
              setRecord((prev) => ({
                ...prev,
                stage_6: {...prev.stage_6, image: value},
              }))
            }
            schema={{}}
          />
        </Ant.Col>
        <Ant.Col span={2} className="label">
          拉亞小編影片
        </Ant.Col>
        <Ant.Col span={6}>
          <Ant.Input
            value={record.stage_4?.video}
            onChange={(e) => {
              setRecord((prev) => ({
                ...prev,
                stage_4: {...prev.stage_4, video: e.target.value},
              }));
            }}
          />
        </Ant.Col>
      </Ant.Row>

      <Ant.Row>
        <Ant.Col span={2} className="label">
          預設門市店長圖
        </Ant.Col>
        <Ant.Col span={22}>
          <RevSingleImageUploader
            value={record.stage_4?.owner_image}
            onChange={(value) =>
              setRecord((prev) => ({
                ...prev,
                stage_4: {...prev.stage_4, owner_image: value},
              }))
            }
            schema={{}}
          />
        </Ant.Col>
      </Ant.Row>

      <Ant.Row>
        <Ant.Col span={24}>超熱賣商品</Ant.Col>
      </Ant.Row>

      <AdminArrayTable
        dataSource={record?.stage_2?.items || []}
        keyExtractor={(record) => record.product_name}
        columns={[
          {
            title: '商品',
            span: 14,
            render: (_, item, idx) => (
              <AdminSelectProductWidget
                value={item.product_name}
                onChange={(name) => {
                  let product;
                  if (name) {
                    product = products.find((p) => p.name === name);
                    let next = [...record.stage_2?.items];
                    next[idx] = {
                      product_name: product.name,
                      qty: null,
                      product_image: product.images[0],
                    };
                    setRecord((prev) => ({
                      ...prev,
                      stage_2: {...prev.stage_2, items: next},
                    }));
                  }
                }}
              />
            ),
          },
          {
            title: '商品圖片',
            span: 8,
            render: (_, item, idx) => (
              <img src={item.product_image?.expected_url} width={120} />
            ),
          },
        ]}
        defaultRecord={{
          product_name: '',
          qty: null,
          product_image: {
            expected_url: '',
          },
        }}
        onChange={(values) => {
          console.log('values', values);

          setRecord((prev) => ({
            ...prev,
            stage_2: {
              ...prev.stage_2,
              items: values,
            },
          }));
        }}
      />
    </React.Fragment>
  );
}
