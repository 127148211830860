import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import * as Antd from 'antd';
import Payment from '../../Components/Payment';
import {ArrowLeft} from '@styled-icons/feather/ArrowLeft';
import * as AppActions from '../../AppActions';
import {useSelectedStore} from '../../Components/SelectStoreModal';
import {useSelectedOrderType} from '../../Components/SelectOrderTypeModal';
import * as LayaCartUtil from '../../Utils/LayaCartUtil';
import * as TextUtil from '../../Utils/TextUtil';
import Theme from '../../Theme';
import {
  getLocalProfile,
  updateLocalProfile,
  validateInvoice,
} from '../../Utils/LocalProfileUtil';
import {
  INVOICE_TYPE,
  INVOICE_PLACEHOLDER,
  DONATION_ORG,
  INVOICE_CARRIER_LABEL,
} from '../../dictionary';
import * as LocalProductHistoryUtil from '../../Utils/LocalProductHistoryUtil';
import {logAddPaymentInfo} from '../../Hooks/useGoogleAnalytics';

export default function CheckoutPaymentPage(props) {
  const localProfile = getLocalProfile();
  const [rememberMe, setRememberMe] = React.useState(
    localProfile.rememberMe || false,
  );
  const [cart] = useOutlet('LayaCart');
  const [cartPriceInOutlet] = useOutlet('LayaCartPrice');
  const [name, setName] = React.useState(localProfile.name || '');
  const [phone, setPhone] = React.useState(localProfile.phone || '');
  // const [email, setEmail] = React.useState(localProfile.email || '');
  const [invoiceType, setInvoiceType] = React.useState(
    localProfile.mobile_carrier
      ? 'mobile_carrier'
      : localProfile.uni_no
      ? 'uni_no'
      : localProfile.donation_code
      ? 'donation_code'
      : 'manual_invoice',
  );
  const [carrier, setCarrier] = React.useState(
    localProfile.mobile_carrier ||
      localProfile.uni_no ||
      localProfile.donation_code ||
      '',
  );

  const [note, setNote] = React.useState('');
  const store = useSelectedStore();
  const orderType = useSelectedOrderType();
  const isTableOrder = !!orderType?.table;
  const allowPayment =
    (isTableOrder ||
      // TextUtil.checkNamePhoneEmail({name, phone, email})
      (TextUtil.checkName(name) && TextUtil.checkPhone(phone))) &&
    (store?.ENABLE_INVOICE ? validateInvoice(invoiceType, carrier) : true) &&
    cart?.items.length > 0;

  async function createOrder({method, prime}) {
    if (rememberMe) {
      updateLocalProfile({
        name,
        phone,
        // email,
        mobile_carrier: invoiceType === 'mobile_carrier' ? carrier : undefined,
        uni_no: invoiceType === 'uni_no' ? carrier : undefined,
        donation_code: invoiceType === 'donation_code' ? carrier : undefined,
      });
    } else {
      updateLocalProfile({
        name: '',
        phone: '',
        // email: '',
        mobile_carrier: undefined,
        uni_no: undefined,
        donation_code: undefined,
      });
    }

    if (!store) {
      alert('尚未選擇店家');
      return;
    }

    AppActions.setLoading(true);
    try {
      const resp = await AppActions.checkout({
        store,
        orderType,
        cart,
        method,
        prime,
        user: {
          name: isTableOrder ? '' : name,
          phone: isTableOrder ? '' : phone,
          // email: isTableOrder ? '' : email,
          ...(invoiceType === 'manual_invoice' ? {} : {[invoiceType]: carrier}),
        },
        note,
      });

      logAddPaymentInfo({
        items: cart.items,
        value: cartPriceInOutlet?.total,
        payment_type: method,
      });

      if (resp.payment_status === 'failed') {
        throw new Error('payment_failed', {order: resp});
      }

      for (const item of cart?.items || []) {
        LocalProductHistoryUtil.addToLocalHistory(item.product);
      }

      if (method === 'line' || method === 'jkopay' || method === 'credit') {
        window.location.href = resp.url;
        return;
      }

      AppActions.navigate(
        `/order/?order_number=${resp.order_number}&clear_cart=true`,
      );
    } catch (ex) {
      console.warn(ex);
      if (ex?.message === 'payment_failed') {
        AppActions.navigate(
          `/checkout?payment_status=failed&payment_type=${method}`,
        );
      } else if (ex?.response?.error === 'order_total_exceed') {
        alert('付款失敗', `訂單超過金額上限 ${store.order_amount_limit}`);
      } else {
        alert(
          '發生錯誤, 可能部分商品補貨中, 或您填寫的資訊有誤, 請重新再試一次',
        );
        AppActions.navigate(`/checkout`);
      }
    }
    AppActions.setLoading(false);
  }

  return (
    <Wrapper>
      <div className="content">
        <div className="back" onClick={() => AppActions.navigate('/checkout/')}>
          <ArrowLeft size={20} />
          <div className="label">上一步</div>
          <div style={{flex: 1}} />
          <div style={{fontSize: 20, color: 'rgb(88, 78, 54)', marginRight: 8}}>
            總金額
          </div>
          <div
            style={{
              fontSize: 32,
              fontWeight: 'bold',
              color: Theme.colors.red,
            }}>
            {cartPriceInOutlet?.total || 0}
          </div>
        </div>

        {!isTableOrder && (
          <React.Fragment>
            <div
              style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
              <div style={{fontSize: 22}}>個人資訊</div>
              <div style={{margin: '0 8px'}}>|</div>
              <div>方便我們與您聯絡</div>
            </div>
            <div className="form" style={{padding: 15}}>
              <div style={{display: 'flex'}}>
                <div style={{flex: 1}} />
                <Antd.Checkbox
                  checked={rememberMe}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    if (updateLocalProfile({rememberMe: checked})) {
                      setRememberMe(checked);
                    }
                  }}>
                  記住我的資訊
                </Antd.Checkbox>
              </div>

              <div className="field">
                <label>姓名</label>
                <Antd.Input
                  value={name}
                  placeholder="您的完整姓名"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="field">
                <label>電話</label>
                <Antd.Input
                  value={phone}
                  placeholder="您的手機, 以09開頭"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>

              {/* <div className="field">
              <label>EMAIL</label>
              <Antd.Input
                value={email}
                placeholder="您的信箱"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div> */}

              <div className="field" style={{display: 'block'}}>
                <label style={{display: 'block', marginBottom: 6}}>
                  訂單備註
                </label>
                <Antd.Input.TextArea
                  value={note}
                  placeholder="訂單備註，限25字以內"
                  onChange={(e) => {
                    let _note = e.target.value;
                    if (_note.length <= 25) {
                      setNote(_note);
                    }
                  }}
                />
              </div>
            </div>
          </React.Fragment>
        )}

        {store?.ENABLE_INVOICE && (
          <React.Fragment>
            <div style={{fontSize: 22, marginBottom: 10}}>發票資訊</div>
            <div className="form" style={{padding: 15}}>
              <div style={{display: 'flex'}}>
                <div style={{flex: 1}} />
                <Antd.Checkbox
                  checked={rememberMe}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    if (updateLocalProfile({rememberMe: checked})) {
                      setRememberMe(checked);
                    }
                  }}>
                  記住我的資訊
                </Antd.Checkbox>
              </div>
              <div className="field">
                <label>發票種類</label>
                <Antd.Select
                  value={invoiceType}
                  onChange={(value) => {
                    setInvoiceType(value);
                    setCarrier('');
                  }}
                  style={{width: '100%'}}>
                  {Object.keys(INVOICE_TYPE).map((x) => (
                    <Antd.Select.Option key={x} value={x}>
                      {INVOICE_TYPE[x]}
                    </Antd.Select.Option>
                  ))}
                </Antd.Select>
              </div>
              <div className="field">
                <label>{INVOICE_CARRIER_LABEL[invoiceType]}</label>
                {['mobile_carrier', 'uni_no'].includes(invoiceType) ? (
                  <Antd.Input
                    value={carrier}
                    onChange={(e) => setCarrier(e.target.value)}
                    placeholder={INVOICE_PLACEHOLDER[invoiceType]}
                  />
                ) : invoiceType === 'donation_code' ? (
                  <Antd.Select
                    value={carrier}
                    onChange={(value) => setCarrier(value)}
                    style={{width: '100%'}}>
                    <Antd.Select.Option disabled value="">
                      請選擇一個捐贈單位
                    </Antd.Select.Option>
                    {Object.keys(DONATION_ORG).map((x) => (
                      <Antd.Select.Option key={x} value={x}>
                        {DONATION_ORG[x]}
                      </Antd.Select.Option>
                    ))}
                  </Antd.Select>
                ) : null}
              </div>
            </div>
          </React.Fragment>
        )}

        <Payment
          disabled={!allowPayment}
          webCallback={createOrder}
          amount={cartPriceInOutlet?.total || 0}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .content {
    padding: 0 10px;
    margin: 0 auto;
    max-width: 720px;

    & > .form {
      background-color: white;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
      margin-bottom: 30px;
      background-color: ${Theme.colors.bgYellow};

      & > .field {
        display: flex;
        align-items: center;
        margin: 10px 0;
        max-width: 360px;

        & label:first-child {
          width: 120px;
        }
      }
    }

    & > .back {
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      cursor: pointer;

      & > .label {
        margin-left: 8px;
        font-size: 1rem;
        top: 0px;
        position: relative;
      }
    }
  }
`;
