import React from 'react';
import styled from 'styled-components';
import * as Theme from '../../Theme';

export default function Modal(props) {
  const {visible, width = 720, onClose} = props;

  return (
    <Wrapper
      visible={visible}
      width={typeof width === 'string' ? width : width + 'px'}
      onScroll={(e) => e.stopPropagation()}>
      <div className="backdrop" onClick={onClose} />
      <div className="modal">{props.children}</div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 100;
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
  overscroll-behavior: none;

  & > .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: ${(props) => (props.visible ? 1 : 0)};
    transition: opacity 500ms;
  }

  & > .modal {
    max-width: ${(props) => props.width};
    margin: 0 auto;
    bottom: 0;
    background-color: #fbfbfb;
    border-bottom-left-radius: 36px;
    border-bottom-right-radius: 36px;
    height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;
    transition: all 500ms;
    transform: translateY(${(props) => (props.visible ? 0 : '-100vh')});
  }
`;
